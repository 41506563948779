@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rubik&display=swap);
@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,600;1,400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cabin:wght@500&display=swap);
body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

button:focus {
  outline: none;
}

a {
  text-decoration: none;
  color: inherit;
}

::-webkit-scrollbar {
  width: 6px;
  background: transparent;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb {
  background: #e3a702;
  border-radius: 8px;
  border: 1px solid transparent;
}

/* for each section */
/* section {
  border: 1px solid blue;
} */
.section_container {
  padding: 4vh 0;
}
/* Title section eg-> Digital Art Portfolio------- (something like this) */
.section_head_container {
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  margin: 0 5vw 1vh;
}
.section_head_container .section-title {
  font-size: 0.9rem;
  font-weight: 500;
  padding: 0;

  letter-spacing: 2px;
  text-transform: uppercase;
  color: #aaa;
  font-family: "Poppins", sans-serif;
}
.section_head_container .section-title:after {
  content: "";
  width: 120px;
  height: 1px;
  display: inline-block;
  background: #ffde9e;
  margin-left: 0.6rem;
}
.section_head_container .section-heading {
  margin: 0;
  font-size: 2rem;
  font-weight: 700;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  color: #151515;
}
.section_head_container .section-description {
  font-size: 0.9rem;
  font-weight: 500;
  color: #212529;
  margin: 0px;
}

@media only screen and (max-width: 600px) {
  .section_head_container .section-title:after {
    width: 100px;
  }

  .section_head_container .section-heading {
    font-size: 25px;
  }
  .section_head_container .section-description {
    line-height: 20px;
  }
}

/* fixed size for all */
div,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
  -webkit-tap-highlight-color: transparent !important;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.popUpOverlay {
  background-color: transparent;
  position: fixed;
  inset: 0;
  z-index: 10000;
}
.popUpRoot {
  color: white;
  width: 40%;
  height: 40%;
  z-index: 1000;
  position: absolute;
  -webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px);
  background: rgb(0 0 0 / 5%);
  -webkit-animation-name: fadeIn;
          animation-name: fadeIn;
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  left: 30%;
  /* left:70%; */
  top:20%;
  /* inset: 100%; */
}

.popUpBox {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  position: fixed;
  display: flex;
  flex-direction: column;
  text-align: center;
  /* top: 0;
  bottom: 0;
  right: 0;
  left: 0; */
  /* margin: auto auto; */
  /* left:70%;
  top:50%; */
  border-radius: 16px;
  background-color: rgba(0, 0, 0, 0.8);
  border: 1px solid #5f4e2e;
}
.popUpBox h4,
.popUpBox h1 {
  font-family: "Raleway", sans-serif;
  font-weight: 500;
}
@media (max-width: 640px) {
  .popUpRoot {
    width: 95%;
    left:3%;
  }
}

.popUpImg {
  width: 50%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  object-fit: contain;
  margin: 0 auto;
}

.nav-services-dropdown .dropdown-menu.show {
  background-color: rgba(0, 0, 0, 0.8862745098039215);
  color: white;
  box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%);
}
.nav-services-dropdown .dropdown-menu.show {
  margin: 0;
  padding: 0;
}
.nav-services-dropdown .dropdown-menu.show li {
  font-size: 15px;
  transition: all 0.1s ease-in;
}
.nav-services-dropdown .dropdown-menu.show li a {
  display: block;
  padding: 6%;
  transition: all 0.1s ease-in;
}
.nav-services-dropdown .dropdown-menu.show li a:hover {
  color: black;
  background-color: rgb(255, 196, 81);
}
/* .nav-services-dropdown .dropdown-menu {
  margin: 0;
  padding: 0;
  display: block;
}
.nav-services-dropdown .dropdown-menu.show {
  margin: 0;
  padding: 0;
  display: block;
} */
@media only screen and (max-width: 900px) {
  .nav-services-dropdown .dropdown-menu.show {
    position: relative !important;
    -webkit-transform: translate(10px, 0) !important;
            transform: translate(10px, 0) !important;
    background-color: transparent;
  }
  .nav-services-dropdown .dropdown-menu.show li a {
    padding: 0;
    background-color: transparent;
  }
  .nav-services-dropdown .dropdown-menu.show li:hover {
    color: rgb(255, 196, 81);
    background-color: transparent;
  }
  .nav-services-dropdown .dropdown-menu.show li a:hover {
    color: rgb(255, 196, 81);
    background-color: transparent;
  }
}

@media only screen and (max-width: 600px) {
  .nav-services-dropdown {
    display: flex;
    flex-direction: column;
  }
  .nav-services-dropdown .dropdown-menu.show li {
    width: -webkit-max-content;
    width: max-content;
  }
}

/* .dropdown {
  position: relative;
}
.dropdown-btn {
  cursor: pointer;
}
.dropdown-title {
  background: transparent;
  border: none;
  text-decoration: none;
  cursor: pointer;
  font: inherit;
  color: inherit;
}
.dropdown-title:after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-menu {
  list-style: none;
  padding-inline-start: 0px;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8862745098039215);
  z-index: 500;
  top: 30px;
  border-radius: 4px;
  width: 180px;
  opacity: 0;
  transform: translateY(-10px);
  transition: all 150ms ease-in-out;
  pointer-events: none;
}
.dropdown-menu li {
  display: none;
  padding: 0px;
  margin: 0px;
}

.dropdown-menu.active {
  opacity: 1;
  transform: translateY(0);
}
.dropdown-menu.active li {
  display: block;
}
.active li:hover {
  background-color: rgb(255, 196, 81);
}
.active li a {
  display: block;
  text-decoration: none;
  color: white;
  padding: 6%;
  pointer-events: auto;
}
.active li a:hover {
  color: black;
}
.dropdown-menu:hover {
  opacity: 1;
  transform: translateY(0);
}

.dropdown-menu li a {
  display: block;
  text-decoration: none;
  color: white;
  padding: 5px 10px;
  pointer-events: auto;
  font-weight: 600;
}

.dropdown-menu li a:hover {
  transition: all 0.2s ease-in-out;
  background-color: #ffc451;
  color: black;
}

@media only screen and (max-width: 900px) {
  .dropdown-title {
    margin-left: 0px;
    padding: 0px;
  }
  .dropdown-menu {
    position: relative;
    opacity: 1;
    background-color: inherit;
    top: 7px;
    padding: 4px 0px;
    left: 30px;
    display: none;
    transition: all 0.3s ease-in-out;
  }
  .dropdown-menu.active {
    display: block;
  }
} */

.navbar-space {
  position: fixed;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  z-index: 95;
  background: transparent;
  /* background: red; */
}
.navbar-container {
  /* height: 60px; */
  position: fixed;
  top: 0 !important;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.85);
  width: 100vw;
  height: 3.5rem;
  -webkit-transform-origin: top;
          transform-origin: top;
  -webkit-transform: scale(1, 0);
          transform: scale(1, 0);
  opacity: 0;
  transition: opacity 0.4s ease-out, -webkit-transform 0.1s ease-out;
  transition: transform 0.1s ease-out, opacity 0.4s ease-out;
  transition: transform 0.1s ease-out, opacity 0.4s ease-out, -webkit-transform 0.1s ease-out;
  /* border: 1px solid red; */
}
.navbar-container.show {
  -webkit-transform: scale(1, 1);
          transform: scale(1, 1);
  opacity: 1;
  transition: opacity 0.1s ease-in;
}
.navbar {
  display: flex;
  justify-content: space-between;
  z-index: 100;
  width: 80%;
  margin: auto;
}
.navbar-container a:hover {
  color: rgb(255, 196, 81);
}
.hamburger-icon {
  display: none;
}
.navbar-logo {
  height: 40px;
  width: 50px;
}

.navbar-links-container {
  display: flex;
  align-items: center;
  /* border: 1px solid red; */
}
.navbar-links {
  color: white;
  list-style: none;
  display: flex;
  grid-gap: 25px;
  gap: 25px;
  font-size: 0.95rem;
  font-weight: 600;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  transition: all 0.3s;
}
.navbar-links a:hover {
  color: rgb(243, 193, 93);
}
.navbar-links-mobile {
  display: none;
}
.navbar-controls {
  display: flex;
  align-items: center;
  grid-gap: 15px;
  gap: 15px;
  /* border: 1px solid red; */
  /* margin-right: min(4vw, 4rem); */
}
.navbar-icon {
  /* stroke: yellow; */
  fill: rgb(243, 193, 93);
  /* outline: yellow; */
  display: inline-block;
  height: 28px;
  width: 28px;
  cursor: pointer;
}

@media only screen and (max-width: 900px) {
  .navbar-links {
    display: none;
  }

  .navbar-links-mobile {
    display: flex;
    flex-direction: column;
    grid-gap: 15px;
    gap: 15px;
    padding: 0px 120px 20px;
    position: absolute;
    top: -300px;
    width: 100%;
    left: 0;
    z-index: 10 !important;
    background-color: inherit;
    color: white;
    list-style: none;
    opacity: 0;
    transition: top 0.8s ease-in-out, opacity 0.2s ease;
  }

  .navbar-links-mobile.active {
    opacity: 1;
    top: 44px;
    transition: top 0.3s ease-in-out, opacity 0.3s ease-in-out 0.2s;
  }

  .navbar-links-mobile li {
    font-size: 0.95rem;
    font-weight: 600;
    padding: 0.25rem 1.5rem;
  }

  .hamburger-icon {
    display: inline-block;
    margin-right: 20vh;
    cursor: pointer;
  }
  .hamburger-icon svg {
    fill: rgb(250, 197, 128);
    width: 32px;
    height: 32px;
  }
}

@media only screen and (max-width: 600px) {
  .navbar {
    width: 95%;
  }
  .hamburger-icon {
    margin-right: 15px;
  }
  .navbar-links-mobile {
    padding-left: 30px;
  }
}

.header-section {
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: top;
  overflow: hidden;
}
.header-container {
  padding-top: 195px;
  display: block;
  text-align: center;
  margin: 0px auto;
}

.header-title-main {
  font-size: 50px;
  line-height: 64px;
  padding: 0px;
  margin: 0px;
  font-weight: 700;
  color: white;
}
.header-subtitle-main {
  color: hsla(0, 0%, 100%, 0.9);
  margin: 8px 0px 0px;
  font-size: 23.19px;
  font-weight: 400;
}

.header-options-main {
  display: grid;
  grid-template-columns: repeat(4, 160px);
  justify-content: center;
  margin-top: 70px;
  grid-gap: 25px;
  gap: 25px;
}

@media only screen and (max-width: 900px) {
  .header-container {
    padding-top: 150px;
  }
  .header-section {
    height: 100vh;
    background-position: 70% 0%;
  }

  .header-title-main {
    font-size: 40px;
    line-height: 42px;
  }
  .header-subtitle-main {
    font-size: 20px;
    line-height: 22px;
  }
  .header-options-main {
    grid-template-columns: repeat(2, 160px);
  }
}
@media only screen and (max-width: 600px) {
  .header-container {
    padding-top: 100px;
  }
}

.header-icon-box {
  border: 1px solid rgba(255, 255, 255, 0.342);
  height: 130px;
  width: 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* padding: 10px 0px 0px; */
  transition: all 0.3s ease-in-out;
}
.header-icon-box:hover {
  border-color: #ffc451;
}
.header-icon-box img {
  height: 50px;
  width: 50px;
}

.header-icon-box h3 {
  color: white;
  cursor: pointer;
  font-size: 20px;
  font-weight: 500;
  margin: 5pxpx 10px;
  line-height: 25px;
  transition: all 0.3s ease-in-out;
}
.header-icon-box h3:hover {
  color: #ffc451;
}

/* some common css derived in index.css */

#website-filters {
  /* border: 1px solid red; */
  padding: 0;
  margin: 0.5rem;
  /* margin: 20px auto 10px auto; */
  list-style: none;
  text-align: center;
  overflow: auto;
  white-space: nowrap;
}

/* Hide scrollbar for Chrome, Safari and Opera */
#website-filters::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
#website-filters {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

#website-filters li {
  cursor: pointer;
  display: inline-block;
  padding: 8px 15px 10px 15px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
  color: #444444;
  margin-bottom: 5px;
  transition: all 0.6s ease-in-out;
  border-radius: 3px;
}

#website-filters li:hover {
  color: rgba(255, 94, 19);
  background-color: transparent;
}

#website-filters li.filter-active {
  color: #151515;
  background: #ffc451;
}

#website-filters li:last-child {
  margin-right: 0;
}

.category {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.carousel-drag {
  display: flex;
  justify-content: center;
}
.mobile_websites_container {
  padding: 0.5rem;
}

.flipcard {
  width: 33vw;
  max-width: 600px;
  min-width: 300px;
  aspect-ratio: 100 / 51;
  background-size: 100% 100%;
  overflow: hidden;
  background-color: transparent;
  border-radius: 8px;
  position: relative;
  margin: 10px;
  box-shadow: rgb(122, 104, 85) 8px 8px 20px;
}

.btn-demo {
  padding: 0;
  position: absolute;
  top: 25px;
  right: 10px;
}

.btn-demo button {
  height: 30px;
  width: 50px;
  border: none;
  border-radius: 5px;
  background-color: rgba(146, 135, 105, 0.4);
  font-size: 16px;
  padding: 0px;
  font-weight: 500;
  color: white;
  -webkit-animation: color-change 2s infinite;
          animation: color-change 2s infinite;
}

@-webkit-keyframes color-change {
  0% {
    color: white;
  }
  50% {
    color: black;
  }
  100% {
    color: white;
  }
}

@keyframes color-change {
  0% {
    color: white;
  }
  50% {
    color: black;
  }
  100% {
    color: white;
  }
}

.btn-demo button:hover {
  cursor: pointer;
  color: rgba(170, 170, 170);
}

.btn-flip {
  position: absolute;
  bottom: 20px;
  right: 5px;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  padding: 0;
  background-color: #be8d03;
  border: none;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
  overflow: hidden;
  z-index: 2;
}

.btn-flip div {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-flip div:hover {
  cursor: pointer;
  -webkit-transform: scale(1.3);
          transform: scale(1.3);
}

.flipcard-back {
  padding: 20px 6px 20px 14px;
}

.website-details {
  height: 100%;
  width: 98%;
  padding-right: 2%;
  overflow: auto;
  text-align: justify;
  font-size: 14px;
  font-weight: 200;
  scroll-behavior: smooth;
  font-family: "Rubik", sans-serif;
  color: black;
  background-color: transparent;
  line-height: 1.8;
}

.website-details::-webkit-scrollbar {
  width: 7px;
  height: 50px;
  background: linear-gradient(#feeab3, transparent);
  border-radius: 8px;
}

.website-details::-webkit-scrollbar-thumb {
  background: linear-gradient(#e3a702, transparent);
  border-radius: 8px;
  border: 1px solid #e3a702;
}
.contact-link {
  margin: 0;
  padding: 0;
  margin-right: 3px;
}

.contact-link:hover {
  cursor: pointer;
}

.contactpic {
  margin: 0;
  padding: 0;
  height: 18px;
  width: 18px;
}

@media only screen and (max-width: 1110px) {
  .flipcard {
    height: auto;
    width: auto;
    aspect-ratio: 100 / 51;
    min-width: 300px;
    min-height: 0;
    max-height: none;
    max-width: 500px;
    /* margin: 10px 0; */
    margin: 5px auto;
    box-shadow: none;
  }
  .react-card-flip:nth-child(2n) {
    margin-bottom: 20px;
  }
  .flipcard .img-fluid {
    height: 100%;
    width: auto;
    border-radius: 8px;
  }
}

.graphics-phone-option {
  display: none;
}
.graphics-categories {
  margin: 3vh 0;
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 1.5rem;
  gap: 1.5rem;
  justify-content: center;
}
.graphics-phone-item {
  display: block;
}

@media only screen and (max-width: 600px) {
  .graphics-categories {
    display: block;
    min-height: 650px;
  }
  .graphics-phone-option {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    color: #555;
  }
  .graphics-phone-option div {
    display: flex;
    grid-gap: 5px;
    gap: 5px;
    -webkit-animation: marquee 19s linear infinite;
            animation: marquee 19s linear infinite;
    margin: 20px 2px;
  }
  .graphics-phone-option span {
    white-space: nowrap;
    font-size: 16px;
    font-weight: 500;
  }
  .graphics-phone-option span.active {
    font-weight: bold;
    color: #ff5e13;
  }

  .graphics-phone-item {
    display: none;
  }
  .graphics-phone-item.active {
    display: block;
  }
}

@-webkit-keyframes marquee {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(-100%, 0);
            transform: translate(-100%, 0);
  }
}

@keyframes marquee {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(-100%, 0);
            transform: translate(-100%, 0);
  }
}

.ImageViewer_graphics_image_viewer__pOjdA {
  --color1: #cda45e;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;

  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  /* display: flex;
  align-items: center;
  justify-content: center; */
  /* overflow-y: scroll; */
  border: 2px solid var(--color1);
  background: rgba(0, 0, 0, 0.8);
}
.ImageViewer_graphics_img_viewer_container__2MkHf {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  -webkit-animation: ImageViewer_expand__1dOhd 0.8s ease-in-out;

          animation: ImageViewer_expand__1dOhd 0.8s ease-in-out;
}

.ImageViewer_btn_close__215s5 {
  cursor: pointer;
  position: absolute;
  z-index: 2;
  top: 3%;
  right: 2%;
  -webkit-transform: scale(1.8);
          transform: scale(1.8);
  color: white;
  background: none;
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
}
.ImageViewer_graphics_image__17x_S {
  /* max-height: 80vh;
  max-width: 70vw; */
  /* object-fit: cover; */
  border: 1px solid var(--color1);
}
.ImageViewer_des_container__22XKB {
  position: absolute;
  inset: auto 0 0 0;

  /* border: 1px solid var(--color1); */
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.ImageViewer_graphics_image_details__3aVOI {
  /* border: 1px solid var(--color1); */

  max-width: 50%;

  z-index: 12;
  -webkit-transform: translateY(50%);
          transform: translateY(50%);
  color: white;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  /* text-align: center; */
  padding: 1rem 0.8rem;
}
.ImageViewer_graphics_image_viewer_title__3p7dN {
  color: white;

  display: flex;
  align-items: baseline;
  justify-content: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;

  margin-bottom: 15px;
  /* border: 1px solid red; */
}

.ImageViewer_des_container__22XKB:hover .ImageViewer_graphics_image_details__3aVOI {
  -webkit-transform: translateY(0%);
          transform: translateY(0%);
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}
.ImageViewer_title__31be5 {
  position: relative;
  max-width: 80%;
}
.ImageViewer_graphics_image_details__3aVOI h3 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  font-size: clamp(1.1rem, 4vw, 1.3rem);
}
.ImageViewer_graphics_image_details__3aVOI p {
  text-align: left;
  opacity: 0;
  line-height: 20px;
  font-size: 14px;
  transition: all 0.3s ease-in-out;
}
.ImageViewer_graphics_image_details__3aVOI:hover p {
  opacity: 1;
  transition: all 0.3s ease-in-out;
}

.ImageViewer_graphics_image_viewer_title__3p7dN img {
  cursor: pointer;
}

@-webkit-keyframes ImageViewer_scrollText__1bs8k {
  from {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
  to {
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
}

@keyframes ImageViewer_scrollText__1bs8k {
  from {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
  to {
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
}

@media only screen and (max-width: 600px) {
  .ImageViewer_btn_close__215s5 {
    cursor: pointer;
    position: absolute;
    z-index: 2;
    top: 3%;
    right: 5%;
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
    color: white;
    background: none;
    -webkit-backdrop-filter: blur(2px);
            backdrop-filter: blur(2px);
  }
  .ImageViewer_graphics_image_viewer_title__3p7dN img {
    height: 20px;
    width: 20px;
  }

  .ImageViewer_graphics_image_details__3aVOI {
    min-width: 90%;
    max-width: 90%;
    -webkit-transform: translateY(70%);
            transform: translateY(70%);
  }
}

.digital-art-portfolio {
  width: 28vw;
  min-width: 350px;
  border-radius: 10px;
  margin: 0px auto;
  padding: 18px 0px 30px;
  box-shadow: 0 54px 55px rgb(0 0 0 / 25%), 0 -12px 30px rgb(0 0 0 / 12%),
    0 4px 6px rgb(0 0 0 / 12%), 0 12px 13px rgb(0 0 0 / 17%),
    0 -3px 5px rgb(0 0 0 / 9%);
  background-image: linear-gradient(
    180deg,
    #f4ecd8,
    #f2d9a8,
    #f1c578,
    #f2af49,
    #f4960c
  );
}

.digital-art-portfolio h1 {
  margin: 0px auto;
  padding: 0px;
  margin-bottom: 15px;
  text-align: center;
  text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.2);
}

.digital-arts {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 6px 8px;
  gap: 6px 8px;
  padding: 0px 20px;
}
.digital-art {
  width: 100%;
  padding-top: 100%;
  border: 2px solid goldenrod;
  cursor: pointer;
  transition: all 0.15s ease-in-out;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 175%;
  box-shadow: 0 54px 55px rgb(0 0 0 / 25%), 0 -12px 30px rgb(0 0 0 / 12%),
    0 4px 6px rgb(0 0 0 / 12%), 0 12px 13px rgb(0 0 0 / 17%),
    0 -3px 5px rgb(0 0 0 / 9%);
}
.digital-art:hover {
  /* transform: scale(1.05); */
  background-size: 210%;
  transition: all 0.3s ease-in-out;
}

@media only screen and (max-width: 600px) {
  .digital-art-portfolio {
    width: 95%;
    min-width: auto;
  }
}

.cards_section {
  min-height: 30vh;
  font-size: clamp(0.9rem, 3vw, 1rem);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* form */
.md-stepper-horizontal {
  display: table;
  width: 100%;
  margin: 0 auto;
  background-color: #ffffff;
}
.md-stepper-horizontal .md-step {
  display: table-cell;
  position: relative;
  padding: 24px;
}
.md-stepper-horizontal .md-step:hover,
.md-stepper-horizontal .md-step:active {
  background-color: rgba(0, 0, 0, 0.04);
}
.md-stepper-horizontal .md-step:active {
  border-radius: 15% / 75%;
}
.md-stepper-horizontal .md-step:first-child:active {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.md-stepper-horizontal .md-step:last-child:active {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.md-stepper-horizontal .md-step:hover .md-step-circle {
  background-color: #757575;
}
.md-stepper-horizontal .md-step:first-child .md-step-bar-left,
.md-stepper-horizontal .md-step:last-child .md-step-bar-right {
  display: none;
}
.md-stepper-horizontal .md-step .md-step-circle {
  width: 34px;
  height: 34px;
  margin: 0 auto;
  background-color: #999999;
  border-radius: 50%;
  text-align: center;
  line-height: 30px;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  cursor: pointer;
}

.md-stepper-horizontal.yellow .md-step.active .md-step-circle {
  background-color: #ffc451;
}
.md-stepper-horizontal .md-step.active .md-step-circle {
  background-color: rgb(33, 150, 243);
}
.md-stepper-horizontal .md-step.done .md-step-circle:before {
  font-family: "FontAwesome";
  font-weight: 100;
  content: "\f00c";
}
.md-stepper-horizontal .md-step.done .md-step-circle *,
.md-stepper-horizontal .md-step.editable .md-step-circle * {
  display: none;
}
.md-stepper-horizontal .md-step.editable .md-step-circle {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.md-stepper-horizontal .md-step.editable .md-step-circle:before {
  font-family: "FontAwesome";
  font-weight: 100;
  content: "\f040";
}
.md-stepper-horizontal .md-step .md-step-title {
  margin-top: 16px;
  font-size: 16px;
  font-weight: 600;
}
.md-stepper-horizontal .md-step .md-step-title,
.md-stepper-horizontal .md-step .md-step-optional {
  text-align: center;
  color: rgba(0, 0, 0, 0.26);
}
.md-stepper-horizontal .md-step.active .md-step-title {
  font-weight: 600;
  color: rgba(0, 0, 0, 0.87);
}
.md-stepper-horizontal .md-step.active.done .md-step-title,
.md-stepper-horizontal .md-step.active.editable .md-step-title {
  font-weight: 600;
}
.md-stepper-horizontal .md-step .md-step-optional {
  font-size: 12px;
}
.md-stepper-horizontal .md-step.active .md-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.md-stepper-horizontal .md-step .md-step-bar-left,
.md-stepper-horizontal .md-step .md-step-bar-right {
  position: absolute;
  top: 36px;
  height: 1px;
  border-top: 1px solid #dddddd;
}
.md-stepper-horizontal .md-step .md-step-bar-right {
  right: 0;
  left: 50%;
  margin-left: 20px;
}
.md-stepper-horizontal .md-step .md-step-bar-left {
  left: 0;
  right: 50%;
  margin-right: 20px;
}

.mycontainer {
  margin: auto;
}

.application-container {
  margin: auto;
}

.scroll_div {
  width: 100%;
  height: 500px;
  background-color: #26201f;
  overflow: scroll;
}

#exampleFormControlTextarea1::-webkit-input-placeholder {
  font-style: italic;
  font-size: 12px;
  padding: 5px;
}

#exampleFormControlTextarea1:-ms-input-placeholder {
  font-style: italic;
  font-size: 12px;
  padding: 5px;
}

#exampleFormControlTextarea1::placeholder {
  font-style: italic;
  font-size: 12px;
  padding: 5px;
}

.checkboxes {
  margin-left: 10%;
}

.checkboxeslabel {
  margin-left: 18%;
}

.form-group .form-control {
  padding: 2px;
}

.feedback {
  font-size: 12px;
  color: red;
}

/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}
.cv-input {
  width: 100%;
  border: 1px solid #dc3545;
  border-radius: 4px;
  overflow: hidden;
  height: auto;
}
.cv-input p {
  float: left;
  display: inline;
  padding: 0%;
  margin: 0%;
  overflow: hidden;
  width: 70%;
}
.highlight-item {
  -webkit-animation: border-animation 2s;
          animation: border-animation 2s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  background-color: white;
}
@-webkit-keyframes border-animation {
  0% {
    background-color: rgb(254, 200, 106);
  }
  100% {
    background-color: rgb(244, 236, 215);
  }
}
@keyframes border-animation {
  0% {
    background-color: rgb(254, 200, 106);
  }
  100% {
    background-color: rgb(244, 236, 215);
  }
}
input[type="file"] {
  color: transparent !important;
  display: inline;
  width: 30%;
  width: 100px;
  float: right;
  border: none;
}
button[type="submit"]:hover {
  cursor: no-drop;
}

.cross {
  font-size: 25px;
  position: absolute;
  -webkit-transform: translateY(-16px);
          transform: translateY(-16px);
  left: 100%;
  color: gray;
  z-index: 1;
  cursor: pointer;
}

.filters {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
}
.seperate {
  display: none;
}

.section-title__2 h2 {
  font-size: 14px;
  font-weight: 500;
  padding: 0;
  line-height: 1px;
  margin: 0 0 5px 0;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #aaaaaa;
  font-family: "Poppins", sans-serif;
}

.section-title__2 h2::after {
  content: "";
  width: 120px;
  height: 1px;
  display: inline-block;
  background: #ffde9e;
  margin: 4px 10px;
}

.section-title__2 p {
  margin: 0;
  margin: 0;
  font-size: 36px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  color: #151515;
}
.categoryone button {
  background: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-size: 18px;
  font-weight: 600;
  line-height: 0;
  text-transform: uppercase;
  color: #444444;
  margin-bottom: 0px;
  transition: all 0.6s ease-in-out;
  border-radius: 3px;
  border: 0px;
  padding: 18px 10px;
  margin-right: 5px;
  margin-top: 8px;
}

.categorytwo button {
  color: #444;
  cursor: pointer;
  display: inline-block;
  padding: 4px 8px 4px 8px;
  font-size: 15px;
  font-weight: 700;
  line-height: 2;
  text-transform: uppercase;
  border: transparent;
  transition: all 0.8s ease-in-out;
  margin-right: 50px;
  border-radius: 8%;
  border: transparent;
  background-color: transparent;
}
.categoryone .button.is-checked {
  background-color: #ffc451;
}
.categorytwo .button.is-checked {
  transition: all 0.2s ease-in-out;
  color: #ee920d;
  border: transparent;
  /* padding: 2px 2px 2px 2px;
  margin-right: 35px; */
}
.categoryone .button:active {
  box-shadow: inset 0 1px 10px hsla(0, 0%, 0%, 0.8);
}
.categorytwo .button:active {
  transition: all 0.8s ease-in-out;
}

#snackbar {
  visibility: hidden; /* Hidden by default. Visible on click */
  min-width: 250px; /* Set a default minimum width */
  margin-left: 125px; /* Divide value of min-width by 2 */
  background-color: #333; /* Black background color */

  color: #fff; /* White text color */
  text-align: center; /* Centered text */
  border-radius: 2px; /* Rounded borders */
  padding: 16px; /* Padding */
  position: fixed; /* Sit on top of the screen */
  z-index: 1; /* Add a z-index if needed */
  /* Center the snackbar */
  bottom: 30px; /* 30px from the bottom */
}

/* Show the snackbar when clicking on a button (class added with JavaScript) */
#snackbar.show {
  visibility: visible; /* Show the snackbar */
  /* Add animation: Take 0.5 seconds to fade in and out the snackbar.
  However, delay the fade out process for 2.5 seconds */
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}
.vl {
  height: 300px;
  overflow: hidden;
  border-right: 1px solid rgb(69, 58, 56);
  border-left: 1px solid rgb(69, 58, 56);
  border-bottom: 16px solid #26201f;
  border-top: 1px solid rgb(69, 58, 56);
  padding: 4%;
}
.topic {
  padding-bottom: 5px;
}
.keywords {
  padding-bottom: 25px;
}
.padding-0 {
  padding-right: 0;
  padding-left: 0;
}
.closeee {
  position: absolute;
  z-index: 10;
  height: 30px;
  width: 30px;
  bottom: 30%;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
  border-radius: 8px;
  cursor: pointer;
}

.btnholder1 {
  position: absolute;
  bottom: 0;
  right: 0;
  margin-right: 0.93rem;
}

.btnholder1 button {
  margin: 0.5rem 0.5rem;
  margin-left: unset;
  background: hsla(0, 0%, 50%, 0.267);
  box-shadow: 0 8px 32px 0 rgb(31 38 135 / 37%);
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  border-radius: 3px;
  border: 1px solid hsla(0, 0%, 100%, 0.18);
  color: white;
}

.btnholder1 button:hover {
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
}

#cat__one,
#categorytwo {
  white-space: nowrap;
}

#categoryone::-webkit-scrollbar,
#categorytwo::-webkit-scrollbar {
  height: 0rem;
}

.vl .MuiSvgIcon-root {
  font-size: 2.5rem !important;
  cursor: pointer;
}

@-webkit-keyframes pulse-border {
  0% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
            transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
            transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}

@keyframes pulse-border {
  0% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
            transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
            transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}

.clickhere {
  color: #ffc451;
  background-color: transparent;
  border: 0px;
}
.cross {
  font-size: 25px;
  position: absolute;
  -webkit-transform: translateY(-16px);
          transform: translateY(-16px);
  left: 100%;
  color: gray;
  z-index: 1;
  cursor: pointer;
}
.clickhere2 {
  color: black;
  background-color: transparent;
  border: 0px;
}

.carousel-control-prev-icon {
  position: absolute;
  left: 10%;
}
.carousel-control-next-icon {
  position: absolute;
  right: 10%;
}
.curimage {
  height: auto;
  position: relative;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.Modal {
  margin: auto;
  width: 600px;
  font-size: 1em;
  border-radius: 8px;
  background-color: #fff;
  padding: 25px;
  height: 600px;
  overflow-x: hidden;
  overflow-y: scroll;
  margin-top: 100px;
}

.Modal::-webkit-scrollbar {
  width: 7px;
  height: 50px;
  background: linear-gradient(#feeab3, transparent);
  border-radius: 8px;
}

.Modal::-webkit-scrollbar-thumb {
  background: linear-gradient(#e3a702, transparent);
  border-radius: 8px;
  border: 1px solid #e3a702;
}
.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(3, 0, 0, 0.9);
}

.Overlay1 {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
}

@media (min-width: 1920px) and (max-width: 2560px) {
  .Modal {
    margin-top: 150px;
  }
  .Modal2 {
    margin-top: 150px;
  }
}
.videomodal {
  display: flex;
}
.Modal4 {
  margin: auto;
  left: 0;
  right: 0;
  top: 1%;
  bottom: 0;
  margin: auto;
  position: absolute;
  z-index: 10000;
  width: 60%;
  background: #fff;
  border-radius: 8px;
  padding: 1.5%;
  height: 83vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.Overlay4 {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.15);
}

.projdesc {
  position: relative;
  line-height: 1.2;
  overflow-y: hidden;
  overflow-x: hidden;
  text-align: justify;
  line-height: 1.25;
  justify-content: center;
}

.projtit {
  text-align: center;
}

.projkey {
  text-align: center;
}
html {
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  -ms-font-smoothing: antialiased !important;
}
body {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  color: #555555;
}

.mycontainer {
  margin: auto;
}

.application-container {
  margin: auto;
}

.scroll_div {
  width: 100%;
  height: 500px;
  background-color: #26201f;
  overflow: scroll;
}

.joinourteam {
  /* background-color: #26201f; */
  height: auto;
  width: 100%;

  height: 70%;
  margin: auto;
}

.long {
  overflow-x: hidden;
  /* background-color: #26201f; */
  margin: auto;
  width: 100%;
}

.owlarousel__1 {
  width: calc(100% - 75.85px - 60px);
  margin: 0 30px;
}

.topic {
  color: aliceblue;
  font-family: "IBM Plex Sans", sans-serif;
  text-align: center;
  font-size: 20px;
}

.topic:hover {
  color: grey;
}
.keywords {
  color: #555555;
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 15px;
  margin-bottom: -20px;
}
.duration {
  color: #555555;
  font-size: 14px;
}

.long::-webkit-scrollbar {
  width: 8px;
  height: 10px;
  background: transparent;
  border-radius: 8px;
}
::-webkit-scrollbar {
  width: 6px;
  background: transparent;
  border-radius: 8px;
}
.long::-webkit-scrollbar-thumb {
  background: #ffc451;
  border-radius: 8px;
  border: 1px solid black;
}
::-webkit-scrollbar-thumb {
  background: #e3a702;
  border-radius: 8px;
  border: 1px solid transparent;
}
.jobdescription {
  color: white;
  font-size: 16px;
  text-align: justify;
  line-height: 1.2;
  justify-content: center;
  font-family: "Cabin", sans-serif;
  margin: auto;
}

.form-row {
  display: flex;
  margin-bottom: 1rem !important;
}

.Modal2 {
  margin-top: 100px;
  margin: auto;
  margin-top: 100px;
  font-size: 1em;
  border: 0px;
  background: #fff;
  border-radius: 8px;
  width: 500px;
  padding: 25px;
  height: 330px;
}

.Overlay2 {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(3, 0, 0, 0.92);
}

.close {
  background: none;
  border: none;
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}

.col {
  margin-right: 1rem !important;
}

.span-in-div {
  padding-top: 0px;
  margin-top: 0px;
}
.deadline {
  color: #555555;
  font-size: 14px;
  text-align: center;
  padding-bottom: 2px;
  margin-top: 0px;
  padding-top: 0px;
}
.applicationId {
  color: #555555;
  font-size: 14px;
  margin-top: 0px;
  padding-top: 0px;
  text-align: center;
  bottom: 0px;
}

@media only screen and (max-width: 600px) {
  .categorytwo .button.is-checked {
    color: #ee920d;
    border: transparent;
    /* padding: 2px 2px 2px 2px;
    margin-right: 35px; */
  }
  .cross {
    position: absolute;
    -webkit-transform: translateY(-16px);
            transform: translateY(-16px);
    left: 95%;
  }
  .categoryone button {
    color: black;
    cursor: pointer;
    display: inline-block;
    padding: 8px;
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
    text-transform: uppercase;
    color: #444444;
    transition: all 0.6s ease-in-out;
    border-radius: 3px;
    border: 0px;
  }
  .categorytwo button {
    color: black;
    cursor: pointer;
    display: inline-block;
    padding: 0px;
    font-size: 11px;
    font-weight: 600;
    line-height: 1;
    text-transform: uppercase;
    color: #444444;
    border-radius: 3px;
    border: 0px;
    margin-left: 0px;
    margin-right: 0px;
  }

  .topic {
    font-size: 16px;
  }

  .keywords {
    font-size: 13px;
  }
  .jobdescription {
    font-size: 15px;
  }

  .Modal4 {
    width: 95%;
  }

  .projkey {
    text-align: center;
    color: #ffc451;
  }
  .projdesc {
    font-size: 14px;
    padding: 5px;
  }

  #snackbar {
    margin: auto;
    min-width: 90px; /* Set a default minimum width */
    margin-left: -5px;
    font-size: 13px;

    /* Divide value of min-width by 2 */
    background-color: #333; /* Black background color */
    color: #fff; /* White text color */
    text-align: center; /* Centered text */
    border-radius: 2px; /* Rounded borders */
    padding: 16px; /* Padding */
    position: fixed; /* Sit on top of the screen */
    z-index: 1; /* Add a z-index if needed */
    /* Center the snackbar */
    bottom: 30px; /* 30px from the bottom */
  }
}

.jobdescription a:link {
  color: orange;
}
.jobdescription a:hover {
  color: white;
}

.notice {
  font-style: italic;
  font-size: 13px;
  text-align: center;
}
/*=====================================================================*/

#app-flters {
  padding: 0;
  margin: 0 auto 20px auto;
  list-style: none;
  text-align: center;
}

#app-flters li {
  cursor: pointer;
  display: inline-block;
  padding: 8px 15px 10px 15px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
  color: #444444;
  margin-bottom: 5px;
  transition: all 0.6s ease-in-out;
  border-radius: 8px;
}

#app-flters li:hover,
#app-flters li.filter-active {
  color: #151515;
  background: #ffc451;
}

#app-flters li:last-child {
  margin-right: 0;
}

.portfolio .portfolio-wrap .portfolio-info h4 {
  font-size: 20px;
  color: black;
  font-weight: 600;
}
.dropdown-cus {
  margin-left: 10px;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}

.dropdown-cus button {
  font-size: 13px;
}

.filter-button {
  border: none;
  font-size: 0.9rem;
}
.filter-button:hover {
  color: #b88700;
}
.hiringtext {
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: 500;
  text-align: justify;
  justify-content: center;
}

.slick-track {
  display: flex !important;
}

.slick-slide {
  width: unset !important;
}

/*--------------------------------------------------------------
# Portfolio
--------------------------------------------------------------*/

.portfolio-container {
  /* border: 1px solid red; */
  width: 80%;
  height: 100%;
}

/*--------------------------outer filter--------------------*/

/* @media only screen and (max-width: 323px) {
  .section-title__2 h2::after {
    width: 50px;
    height: 1px;

    margin: 4px 2px;
    margin-right: 0px;
  }
}
@media only screen and (max-width: 300px) {
  .section-title__2 h2::after {
    width: 40px;
    margin-left: 0px !important;
    margin-right: 0px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
} */
@media only screen and (max-width: 800px) and (min-width: 628px) {
  .portfolio .container {
    margin: auto;
    width: 330px;
  }
}
@media only screen and (max-width: 800px) {
  .application-container {
    width: 95%;
  }
}

@media only screen and (min-width: 1000px) and (max-width: 1400px) {
  .portfolio .container {
    margin: auto;
    width: 1600px;
  }
}

@media only screen and (max-width: 600px) {
  .noblog__txt {
    font-size: 0.3rem !important;
  }
  .cross {
    position: absolute;
    -webkit-transform: translateY(-16px);
            transform: translateY(-16px);
    left: 95%;
  }

  .topic {
    font-size: 16px;
  }

  .keywords {
    font-size: 13px;
  }
  .jobdescription {
    font-size: 15px;
  }

  .Modal {
    width: 94%;
    margin-left: 3%;
    margin-top: 20%;
    height: 82%;
  }
  .Modal2 {
    width: 80%;
    margin-left: 10%;
    margin-top: 40%;
  }

  .curimage {
    /* margin-top: 10px; */
    height: auto;
    width: 100%;
  }

  .projkey {
    text-align: center;
    color: #ffc451;
  }
  .projdesc {
    font-size: 14px;
    padding: 5px;
  }

  #snackbar {
    margin: auto;
    min-width: 90px; /* Set a default minimum width */
    margin-left: -5px;
    font-size: 13px;

    /* Divide value of min-width by 2 */
    background-color: #333; /* Black background color */
    color: #fff; /* White text color */
    text-align: center; /* Centered text */
    border-radius: 2px; /* Rounded borders */
    padding: 16px; /* Padding */
    position: fixed; /* Sit on top of the screen */
    z-index: 1; /* Add a z-index if needed */
    /* Center the snackbar */
    bottom: 30px; /* 30px from the bottom */
  }
}

@media only screen and (min-width: 1000px) and (max-width: 1200px) {
  .portfolio .container {
    margin: auto;
    width: 1600px;
  }
}
@media only screen and (max-width: 628px) {
  .categorytwo button {
    color: #444;
    cursor: pointer;
    display: inline-flex;

    justify-content: center;
    padding: 2px 3px 2px 3px;
    font-size: 13px;
    font-weight: 700;
    line-height: 2;
    text-transform: uppercase;
    border: transparent;
    margin-right: 0px;
    border-radius: 8%;
  }
  .categoryone .button.is-checked {
    background-color: #ffc451;
  }
  .categorytwo .button.is-checked {
    color: #ffc451;
    border: transparent;
  }
  .categoryone .button:active {
    box-shadow: inset 0 1px 10px hsla(0, 0%, 0%, 0.8);
  }
  .categorytwo .button:active {
    box-shadow: inset 0 1px 10px hsla(0, 0%, 0%, 0.8);
    border: transparent;
    background: transparent;
  }
  .section-title__2 p {
    font-size: 20px;
  }
  .section-title__2 {
    margin-bottom: 0px;
  }
  .filters {
    align-items: center;
  }
  .seperate {
    display: inline-block;
    font-size: larger;
    font-weight: 500;
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
  }

  .portfolio #portfolio-flters {
    padding: 0;
    margin: 5px 5px 5px 5px;
    list-style: none;
    text-align: center;
  }

  .portfolio #portfolio-flters li {
    cursor: pointer;
    display: inline-block;
    padding: 8px 8px 8px 8px;
    font-size: 12px;
    font-weight: 600;
    line-height: 1em;
    text-transform: uppercase;
    color: #444444;
    margin-bottom: 0px;
    margin-top: 0px;
    transition: all 0.6s ease-in-out;
    border-radius: 3px;
  }

  .portfolio .container {
    margin: auto;
    width: 330px;
    height: auto;
  }
}

@media only screen and (max-width: 800px) and (min-width: 628px) {
  .categorytwo button {
    color: #444;
    cursor: pointer;
    display: inline-flex;

    justify-content: center;
    padding: 2px 4px 2px 4px;
    font-size: 13px;
    font-weight: 700;
    line-height: 2;
    text-transform: uppercase;
    border: transparent;
    margin-right: 30px;
    border-radius: 8%;
  }
  .categorytwo .button.is-checked {
    color: #ffc451;
    border: transparent;
  }
}

@media only screen and (max-width: 450px) {
  .Modal2 {
    height: 50%;
  }

  input[type="file"]::before {
    margin-right: 27%;
    padding-right: 0px;
  }

  .application-container {
    width: 95%;
    margin: auto;
    /* height: 640px; */
    /* height: 800px; */
  }
}
@media only screen and (min-width: 265px) and (max-width: 450px) {
  .long {
    max-height: 650px;
    overflow-y: scroll;
  }
}
@media only screen and (max-width: 628px) {
  .categorytwo .button {
    font-size: 13px;
    margin-right: 17px;
  }
  .seperate {
    font-weight: 400;
  }
}
@media only screen and (max-width: 398px) {
  .categorytwo button {
    font-size: 13px;
  }
  .seperate {
    font-weight: 200;
  }
}
@media only screen and (max-width: 320px) and (min-width: 300px) {
  .categorytwo button {
    font-size: 12px !important;
  }
}
@media only screen and (max-width: 449px) {
  .categorytwo .button {
    font-size: 12px;
  }
  .seperate {
    font-weight: 200;
  }
  .showcase_item__content .showcase_item_body {
    height: 350px;
  }
}
@media only screen and (max-width: 468px) and (min-width: 450px) {
  .categorytwo .button {
    font-size: 12px;
  }
  .seperate {
    font-weight: 200;
  }
}
@media only screen and (max-width: 868px) and (min-width: 800px) {
  .categorytwo button {
    margin-right: 30px;
    font-size: 15px;
  }
}
@media only screen and (max-width: 600px) and (min-width: 200px) {
  .filters {
    line-height: 0;
  }
}
@media only screen and (max-width: 299px) {
  .categorytwo .button {
    font-size: 9px;
  }
}

/* Custom job section refrenced from legal02 */
.ContentSection {
  width: 90%;
  margin: auto;
  margin-top: 25px;
}
html {
  scroll-behavior: smooth;
}
.Clientheading {
  font-family: "Merriweather", serif;
  font-weight: bolder;
  font-size: 1.6rem;
  position: relative;
  left: -43%;
  right: 650px;
  font-weight: 900;
  text-transform: uppercase;
  padding: 5% 5% 2% 5%;
  color: #fff;
}
.bg {
  background: black;
  padding: 15px;
}
.handleitems {
  margin-top: 50px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 0px;
  -webkit-column-gap: 0px;
          column-gap: 0px;
  grid-row-gap: 0px;
  row-gap: 0px;
  max-width: 95%;
  margin: 0 auto;
}
.showcase_item {
  /* background-color: #fff; */
  border-color: #000;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  background-image: linear-gradient(to bottom, #f4ebd5, #c0760a);
  color: #000;
  overflow: hidden;
  position: relative;
  border-bottom: none;
}
.showcase_item--single {
  border-bottom: 1px solid #000;
}

.showcase_item_body {
  max-width: 95%;
}
.showcase_item__content {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  text-align: left;
  font-family: "Merriweather", serif;
  max-width: 100%;
  top: 0;
  /* background-color: #fff; */
  padding: 20px;
  transition: opacity 0.3s ease-out;
}
/* .apply_popover {
  position: absolute;
  bottom: 0;
  left: 35%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;
}
.apply_popover--button {
  background: transparent;
  border: none;
  color: #000 !important;
  padding-bottom: 0 !important;
} */
.display--none {
  display: none;
}
.title {
  text-transform: uppercase;
  font-size: 20px;
}
.showcase_item:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 75%;
}

.showcase_item__content .link {
  bottom: 0px;
  padding-left: 20px;
  position: absolute;
  text-align: right;
  right: 26px;
  background: transparent;
  width: 90%;
}
.p-4 {
  padding: 1.5rem !important;
}
h6 {
  font-size: 1rem;
  margin: 8px 0px;
  text-align: left;
}
p {
  line-height: 1.5rem;
}
.showcase_item__content .showcase_item_body {
  font-size: 15px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 15px;
  height: 80%;
}

#show_items::-webkit-scrollbar-track {
  background-color: transparent;
}
.link:before {
  border: solid #000;
  border-width: 0 3px 3px 0;
  content: "";
  display: block;
  margin-top: -4px;
  padding: 3px;
  position: absolute;
  right: -18px;
  top: 50%;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  border-color: #f7323f;
}
@media only screen and (max-width: 1019px) {
  .handleitems {
    margin-top: 50px;
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 0px;
    -webkit-column-gap: 0px;
            column-gap: 0px;
    grid-row-gap: 0px;
    row-gap: 0px;
    max-width: 99%;
    margin: 0 auto;
  }
  .Clientheading {
    font-family: "Merriweather", serif;
    font-weight: bolder;
    font-size: 1.6rem;
    position: relative;
    left: -40%;
    right: 650px;
    font-weight: 900;
    text-transform: uppercase;
    padding: 5% 5% 2% 5%;
    color: #fff;
  }
  .showcase_item:nth-last-child(1) {
    /* border-bottom: 1px solid #000; */
  }
}
/*
  @media only screen and (min-width: 551px) and (max-width: 600px) {
    .showcase_item__content .showcase_item_body {
      height: 280px;
    }
    .handleitems{
      width:70%;
    }
  }

  @media only screen and (min-width: 601px) and (max-width: 650px) {
    .showcase_item__content .showcase_item_body {
      height: 280px;

    }
    .handleitems{
      width:70%;
    }
  }
  @media only screen and (min-width: 651px) and (max-width: 700px) {
    .showcase_item__content .showcase_item_body {
      height: 280px;

    }
    .handleitems{
      width:70%;
    }
  }
  @media only screen and (min-width: 701px) and (max-width: 750px) {
    .showcase_item__content .showcase_item_body {
      height: 280px;
    }
    .handleitems{
      width:70%;
    }
  }

  @media only screen and (min-width: 751px) and (max-width: 768px) {
    .showcase_item__content .showcase_item_body {
      height: 280px;
    }
    .handleitems{
      width:70%;
    }
  }

  @media only screen and (min-width: 769px) and (max-width: 850px) {
    .showcase_item__content .showcase_item_body {
      height: 175px;

    }
    .handleitems{
      width:70%;
    }
  }
  @media only screen and (min-width: 850px) and (max-width: 1019px) {
    .showcase_item__content .showcase_item_body {
      height: 175px;

    }
    .handleitems{
      width:70%;
    }
  } */
@media only screen and (min-width: 680px) and (max-width: 1019px) {
  .handleitems {
    width: 60%;
  }
}
@media only screen and (min-width: 1020px) {
  /* .showcase_item:nth-last-child(2),
  .showcase_item:nth-last-child(2) ~ .showcase_item {
    border-bottom: 1px solid black;
  } */
  .showcase_item:nth-child(even) {
    border-left: none;
  }
}

/* css for main box which enclosing while client carousel cards */
.SlideContent-client {
  --color1: hsl(36, 90%, 40%);
  --color1-light: hsl(36, 90%, 35%);
  --color2: #f4ebd5;
  --color3: rgb(231, 207, 163);

  width: 95vw;
  margin: auto;
  margin: 1vh 0;
}

.mobile_container {
  --head1: clamp(0.7rem, 4vw, 1rem);
  --sub-head: clamp(0.7rem, 4vw, 0.9rem);
  --sub-txt: clamp(0.6rem, 4vw, 0.8rem);
  --desc-font: clamp(0.7rem, 4vw, 0.9rem);
  --card-width: 95vw;
  --card-height: calc(var(--card-width) * 0.66);
  --desc-height: calc(var(--card-width) * 0.2);
  --desc-width: var(--card-width);

  display: flex;
  flex-direction: column;
  grid-gap: 0.6rem;
  gap: 0.6rem;
  /* border: 1px solid black; */
}
/* CARD */
.mobile_container .showcase_item {
  position: relative;
  border: none;
  color: rgb(0, 0, 0);
  width: var(--card-width);
  height: var(--card-height);
  box-shadow: 0 0 5px var(--color1);
  background: linear-gradient(200deg, var(--color2) 10%, var(--color1));
}
.mobile_container .showcase_item.enlarge {
  --card-height: calc(var(--card-width) * 0.66 * 2.05);
}
.showcase_item_body {
  position: absolute;
  inset: 0;
  /* z-index: 2; */
}
/* MAIN DEATAILS */
.mobile_container .main_details {
  position: absolute;
  inset: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  grid-gap: 0.2rem;
  gap: 0.2rem;
  padding: 1rem;
}
.mobile_container .main_details .title {
  line-height: 120%;
  text-transform: uppercase;
  font-size: var(--head1);
}
.mobile_container .main_details .category2 {
  padding: 0;
  margin: 0;
  font-size: var(--sub-txt);
  line-height: 100%;
  /* display: flex;
  align-items: baseline; */
}
.main_details .category2 .head {
  font-size: var(--sub-head);
  font-weight: bold;
  margin-right: 0.2rem;
}
.main_details .desc {
  position: relative;
  width: var(--desc-width);
  height: var(--desc-height);
  overflow: hidden;
  font-size: var(--desc-font);
  /* margin-bottom: 5%; */
  /* border: 1px solid black; */
}

/* END OF MAIN DEATAILS */

.mobile_container .showcase_item .more {
  position: absolute;
  top: 3%;
  height: 84%;
  padding: 0 1rem;
  overflow-y: scroll;
}
/* #show_items::-webkit-scrollbar-track {
  background-color: rgb(255, 255, 255);
} */
.mobile_container .showcase_item .read {
  position: absolute;
  bottom: 1%;
  right: 10%;
  cursor: pointer;
  font-size: var(--sub-head);
}
.mobile_container .read:before {
  /* border: solid rgb(0, 0, 0); */
  border-width: 0 3px 3px 0;
  content: "";
  display: block;
  margin-top: -4px;
  padding: 3px;
  position: absolute;
  right: -10px;
  top: 50%;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  border-color: var(--color3);
}

.showcase_item .apply_popover {
  position: absolute;
  bottom: 1%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.showcase_item .apply_popover--button {
  background: transparent;
  border: none;
  color: #000 !important;
  padding-bottom: 0 !important;
}

/* END OF CARD */
.SlideContent-client .slick-dots {
  bottom: -5vh;
}
.SlideContent-client .slick-dots li {
  -webkit-transform: scale(2.5);
          transform: scale(2.5);
  margin-left: 1.5vw;
}
.SlideContent-client .slick-dots li button::before {
  color: var(--color1) !important;
}

@media (min-width: 400px) and (max-width: 450px) {
  .mobile_container {
    --card-height: calc(var(--card-width) * 0.58);
    --desc-height: calc(var(--card-width) * 0.1);
  }
  .mobile_container .showcase_item.enlarge {
    --card-height: calc(var(--card-width) * 0.58 * 2);
  }
}

@media (min-width: 451px) and (max-width: 600px) {
  .mobile_container {
    --card-height: calc(var(--card-width) * 0.48);
    --desc-height: calc(var(--card-width) * 0.1);
  }
  .mobile_container .showcase_item.enlarge {
    --card-height: calc(var(--card-width) * 0.48 * 2);
  }
}

@media (max-width: 399px) {
  .mobile_container {
    --card-height: calc(var(--card-width) * 0.69);
    --desc-height: calc(var(--card-width) * 0.13);
  }
  .mobile_container .showcase_item.enlarge {
    --card-height: calc(var(--card-width) * 0.69 * 2);
  }
}
/* @media (max-width: 329px) {
  .mobile_container .main_details {
    padding: 0.8rem;
  }
  .mobile_container {
    --head1: clamp(0.8rem, 4vw, 1.1rem);

    --card-height: fit-content;

    --desc-height: 30%;
  }
} */

/* .main-carousel {
  overflow: hidden;
  background-color: rgb(0, 0, 0);
  padding-bottom: 15px;
} */
/* .client-read-button {
  color: black;
  cursor: pointer;
  font-size: 14px;
}

.showcase_item__content .showcase_item_body_carousel {
  font-size: 15px;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 292px;
}
#showcase_item_body_carousel::-webkit-scrollbar-track {
  background: rgb(255, 255, 255);
}
.showcase_item_last {
  padding-top: 75%;
}
.client-carousel-card-title {
  font-size: 20px;
  text-transform: uppercase;
  text-align: left;
}
.client-carousel-card-article {
  text-align: left;
  font-size: 14px;
}
.showcase_odd_client:nth-child(odd) {
  margin-bottom: 5px;
  margin-left: 4px;
  margin-right: 4px;
} */

/* css for dots below carousel card */
/* .client-dots {
  text-align: center;
}

.client-custom li {
  width: 5px;
  height: 10px;
  margin: 4px;
  padding-left: 10px;
  opacity: 100%;
  align-items: center;
  background-color: rgb(231, 247, 206);
  border: 0;
  border-radius: 100px;
  box-sizing: border-box;
  color: rgb(0, 0, 0);
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  min-width: 0px;
  overflow: hidden;
  padding: 0px;
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;
  touch-action: manipulation;
  transition: background-color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s,
    box-shadow 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s,
    color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
}
.client-custom li.slick-active {
  opacity: 100%;
  background-color: rgb(173, 255, 47);
}
.slick-track {
  transform: translate3d(0, 0, 0);
}
.slick-dots li.slick-active button:before {
  opacity: 90%;
} */

/* css to overwrite bootstrap class */
/* .pt-5 {
  padding-top: 0px;
} */
/* @media only screen and (max-width: 320px) {
  .showcase_item__content .showcase_item_body_carousel {
    height: 308px;
  }
  .mobile_container {
    --head1: clamp(0.9rem, 4vw, 1.2rem);
    --sub-head: clamp(0.7rem, 4vw, 0.9rem);
    --sub-txt: clamp(0.6rem, 4vw, 0.8rem);
    --desc-font: clamp(0.7rem, 4vw, 0.9rem);
    --card-height: calc(var(--card-width) * 0.5);
  }
} */

/* @media only screen and (min-width: 321px) and (max-width: 400px) {
  .showcase_item__content .showcase_item_body_carousel {
    height: 370px;
  }
}

@media only screen and (min-width: 401px) and (max-width: 450px) {
  .showcase_item__content .showcase_item_body_carousel {
    height: 444px;
  }
}
@media only screen and (min-width: 451px) and (max-width: 500px) {
  .showcase_item__content .showcase_item_body_carousel {
    height: 505px;
  }
}

@media only screen and (min-width: 501px) and (max-width: 550px) {
  .showcase_item__content .showcase_item_body_carousel {
    height: 580px;
  }
}

@media only screen and (min-width: 551px) and (max-width: 580px) {
  .showcase_item__content .showcase_item_body_carousel {
    height: 670px;
  }
} */

/* for correcting the stopping point */
#ourclients::before {
  content: "";
  display: block;
  height: 80px;
  margin-top: -80px;
  visibility: hidden;
}

/* css for main div which enclosing the whole client section  */
/* padding is given for fixed navbar */

.job_card_container {
  --color1: hsl(36, 90%, 40%);
  --color1-light: hsl(36, 90%, 35%);
  --color2: #f4ebd5;
  --color3: rgb(231, 207, 163);

  --head1: clamp(1rem, 4vw, 1.1rem);
  --sub-head: clamp(0.8rem, 4vw, 0.9rem);
  --sub-txt: clamp(0.7rem, 4vw, 0.8rem);

  /* border: 1px solid red; */
  padding: 0;
  margin: 0 auto;
  margin-top: 2vh;
  width: 98%;
  --width: 25rem;
  /* margin-top: 50px; */
  display: grid;
  grid-template-columns: repeat(auto-fill, var(--width));
  grid-row-gap: 1vw;
  grid-column-gap: 1vw;
  justify-content: center;

  /* border: 1px solid black; */
  min-height: 30vh;

  /* width: 100vw; */
  /* overflow: hidden; */

  /* margin: 0 auto; */
}
/* CARD */

.job_card_container .showcase_item {
  /* text-align: left; */

  position: relative;
  border: none;
  color: rgb(0, 0, 0);
  width: var(--width);
  aspect-ratio: 1/0.8;
  box-shadow: 1px 1px 5px var(--color1);
  background: linear-gradient(200deg, var(--color2) 10%, var(--color1));
}
.showcase_item.selectCard {
  -webkit-animation: fOSelect 10s ease-in-out;
          animation: fOSelect 10s ease-in-out;
}
@-webkit-keyframes fOSelect {
  0% {
    border: 3px solid black;
  }
  100% {
    border: none;
  }
}
@keyframes fOSelect {
  0% {
    border: 3px solid black;
  }
  100% {
    border: none;
  }
}
.showcase_item_body {
  position: absolute;
  inset: 0;
}
/* MAIN DEATAILS */
.job_card_container .main_details {
  position: absolute;
  inset: 0;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 0.3rem;
  gap: 0.3rem;
  padding: 1rem;
}
.job_card_container .main_details .title {
  text-transform: uppercase;
  font-size: var(--head1);
  margin-bottom: 1vh;
  line-height: 120%;
}
.job_card_container .main_details .category2 {
  font-size: var(--sub-txt);
}
.job_card_container .main_details .category2 .head {
  font-size: var(--sub-head);
  font-weight: bold;
  margin-right: 0.2rem;
}
.job_card_container .main_details .desc {
  position: relative;
  width: calc(var(--width) * 0.8);
  height: calc(var(--width) * 0.4);
  overflow: hidden;
}

/* END OF MAIN DEATAILS */

.showcase_item .more {
  position: absolute;
  top: 3%;
  height: 84%;
  padding: 0 1rem;
  overflow-y: scroll;
}
/* #show_items::-webkit-scrollbar-track {
  background-color: rgb(255, 255, 255);
} */
.showcase_item .read {
  position: absolute;
  bottom: 1%;
  right: 10%;
  cursor: pointer;
}
.read:before {
  border: solid rgb(0, 0, 0);
  border-width: 0 3px 3px 0;
  content: "";
  display: block;
  margin-top: -4px;
  padding: 3px;
  position: absolute;
  right: -10px;
  top: 50%;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  border-color: var(--color2);
}

.showcase_item .apply_popover {
  position: absolute;
  bottom: 1%;
  left: 35%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.showcase_item .apply_popover--button {
  background: transparent;
  border: none;
  color: #000 !important;
  padding-bottom: 0 !important;
}
.apply_popover--links {
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
}

.share {
  position: absolute;
  left: 4%;
  bottom: 0.8%;
}
.share a {
  all: unset;
  cursor: pointer;
}
.share i {
  font-size: clamp(1.05rem, 3vw, 1.2rem);
  color: var(--color3);
}

/* END OF CARD */

/* @media only screen and (max-width: 1100px) {
  .clientcontent-main {
    margin-top: unset;
  }
}

@media only screen and (min-width: 581px) and (max-width: 1200px) {
  .handleitems {
    margin-top: 50px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 0px;
    row-gap: 0px;
    max-width: 99%;
    margin: 0 auto;
  }
  .Clientheading {
    font-family: "Merriweather", serif;
    font-weight: bolder;
    font-size: 1.6rem;
    position: relative;
    left: -40%;
    right: 650px;
    font-weight: 900;
    text-transform: uppercase;
    padding: 5% 5% 2% 5%;
    color: rgb(255, 255, 255);
  }
} */

html {
  scroll-behavior: smooth;
}

.contact-section {
  padding: 3vh 0;
}
.address-map-container {
  height: 350px;
  padding-top: 2vh;
}

/*--------------------------------------------------------------
# Team
--------------------------------------------------------------*/

.team .teamwrapper {
  margin: auto;
  overflow: hidden;
  height: 550px;
  width: 100%;
}

.team .swiper-slide {
  font-size: 18px;
  line-height: 1.8;
  box-sizing: border-box;
  padding-top: 1%;
  padding-bottom: 5%;
  padding: 0px;
  margin: auto;
}

/* .teamdesktop {
 
} */
.team {
  /* border: 1px solid red; */
  /* margin-top: 40px; */
  margin: auto;
}

.team .member {
  margin-bottom: 10px;
  overflow: hidden;
  border-radius: 5px;
  box-shadow: 0px 2px 15px rgba(54, 39, 39, 0.1);
}

.team .member-img {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* height: fit-content; */
  /* height: fit-content; */
  /* align-items: flex-start; */

  /* align-items: stretch; */
}

.team .card {
  /* position: absolute; */
  height: 30%;
  bottom: 10%;
  width: 84%;
  background-color: rgba(255, 255, 255, 0.2);
  margin: 0 auto;
  border-top: none !important;
  min-width: 0;
  /* word-wrap: break-word;
  background-clip: border-box; */
  border-radius: 0.25rem;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.team .card-body {
  /* box-shadow: 12px 0 15px -4px #888;*/
  box-shadow: 1px 9px 13px 1px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 1px 9px 13px 1px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 1px 9px 13px 1px rgba(0, 0, 0, 0.75);
  /*box-shadow: inset 0px 9px 15px rgba(210,210,210);*/
  min-height: 1px;
}

.team .card-text {
  overflow: auto;
  height: 55%;
  padding-right: 3%;
}

.team .card-text::-webkit-scrollbar {
  width: 5px;
  border-radius: 3px;
}

/* Track */

.team .card-text::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}

/* Handle */

.team .card-text::-webkit-scrollbar-thumb {
  background: #b3b3b3;
  border-radius: 3px;
}

/* Handle on hover */

.team .card-text::-webkit-scrollbar-thumb:hover {
  background: #b3b3b3;
  border-radius: 3px;
}

.team .teamdesktop {
  display: block;
  margin: auto 10vw;
  margin-top: 2vh;
}

.team .teammobile {
  display: none;
}

.team .card-link i {
  align-items: center;
  font-size: 25px;
  padding: 0px;
  margin: auto;
  color: #ffc451;
}

.team .cardsocial {
  /* margin: auto;
  align-items: center; */
  text-align: center;
  display: flex;
  align-items: baseline;
  justify-content: center;
  margin-top: 6%;
}

.team .teamim {
  box-shadow: 10px 0 11px -4px #888;
}

.team .card-text {
  font-size: 12px;
  text-align: justify;
}

.team .member .social {
  position: absolute;
  left: 0;
  bottom: 30px;
  right: 0;
  opacity: 0;
  transition: ease-in-out 0.3s;
  text-align: center;
}

.team .member .social a {
  transition: color 0.3s;
  color: #151515;
  margin: 0 3px;
  /* padding-top: 7px; */
  border-radius: 4px;
  width: 36px;
  height: 36px;
  background: rgba(255, 255, 255, 0.8);
  display: inline-block;
  transition: ease-in-out 0.3s;
  color: #ffc451;
}

.team .member .social a:hover {
  color: #fff;
  background: #ffc451;
}

.team .member .social i {
  font-size: 18px;
}

.team .member .member-info {
  padding: 25px 15px;
}

.team .member .member-info h4 {
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 18px;
  color: #151515;
}

.team .member .member-info span {
  display: block;
  font-size: 13px;
  font-weight: 400;
  color: #aaaaaa;
}

.team .member .member-info p {
  font-style: italic;
  font-size: 14px;
  line-height: 26px;
  color: #777777;
}

.team .member:hover .social {
  opacity: 1;
  bottom: 15px;
}

.joinDivHome {
  /* margin-top: -30px; */
  margin-bottom: 40px;
}

.joinBtnHome {
  /* border: 2px solid #151515; */
  border: none;
  border-radius: 5px;
  padding: 5px 15px;
  background-color: #ffc451;
  color: #151515;
  font-size: 20px;
  /* box-shadow: 5px 5px 30px gray; */
}

.joinBtnHome:hover {
  color: #ffc451;
  background-color: #151515;
}

.team .card-title {
  padding: 0px;
  font-size: 14px;
  color: rgba(20, 20, 20);
}

.team .card-subtitle {
  font-size: 13px;
  color: rgb(20, 20, 20);
}

.team .head {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
}

@media (min-width: 1500px) and (max-width: 2560px) {
  .team .card-title {
    padding: 0px;
    font-size: 1.03vw;
  }
  .team .card-subtitle {
    color: rgb(70, 70, 70);
    font-size: 1vw;
  }
  .team .card-text {
    font-size: 0.95vw;
    text-align: justify;
  }
}

/* .teammobile {
  display: block;
} */

@media only screen and (max-width: 992px) {
  .team .checkteam-container {
    width: 95% !important;
    margin: auto !important;
  }
  .team .teammobile {
    display: block;
  }
  .team .card {
    height: 250px;
    /* bottom: 15%; */
  }
  .team .swiper-slide {
    background-color: transparent;
    padding: 0%;
    margin: auto;
  }
  /* .team .checkteam-container {
    margin: auto;
    width: 90%;
    margin-bottom: -11%;
  } */
  .team .teamdesktop {
    width: 100%;
    display: none;
  }
  .row {
    /* border: 1px solid purple; */
    display: flex;
  }
  .team .card-title {
    color: rgb(19, 16, 16);
    padding: -10px;
    font-size: 14px;
  }
  .team .card-body {
    padding: 5%;
  }
  .team .card-subtitle {
    font-size: 13px;
  }
  .mob-head {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    -webkit-backdrop-filter: blur(3px);
            backdrop-filter: blur(3px);
  }
  .team .col-12 {
    /* width: 40%; */
    min-width: 45%;
    max-width: 50%;
    margin: auto;
  }
}

@media only screen and (max-width: 600px) and (min-width: 320px) {
  .team .teamwrapper {
    margin-bottom: 70px;
    overflow-y: hidden;
  }
  .team .col-12 {
    width: 80%;
    max-width: 90%;
    min-width: 80%;
  }
}

.swiper-container {
  width: 80%;
  overflow: hidden;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: 90%;
}

.slider {
  margin-top: 50px;
}

.sliderheading {
  padding-bottom: 25px;
  text-align: center;
  font-size: 1.9vw;
  font-weight: 500;
  color: black;
  font-family: "Poppins", sans-serif;
  text-shadow: 2px 7px 5px rgba(0, 0, 0, 0.3),
    0px -4px 10px rgba(255, 255, 255, 0.3);
  left: 80px;
}

.swiper-button-next {
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAIqSURBVEhLzZVbT9RQEMdn2l3wSROzBu8YQlyib/qw2QDC7r6YEMBLfPLz+YJZw0LkCVmFgPIsXlCM4i1ePgGm3XGmZ9qwddue7oPhl7Sdmab9n/9Mcwr/C9RrT2ihehIG8REQlsBxZ3Fu/aPeyk2iEK1UjsOB2wbEa1raB/Kn8faLT5rnwtHrvxy49w+JCMOAbpualfOa5yJZqDDwkM87Jom4xC1co+XqOc2tSZ9RqzIEVHjGYdlUFKL3UKAazj7/ppVMUoUEao2fBQIRGzWViHf8dA3nN79rnkpy6xTzIrfO4QdTibjMC1gLFmJBpqMQWhq/yC9u8zGipZBdfks9y1mmoxCc2/wMHrEz/KqlkHLgrDl5RvOeWAsJeHdrH1xH2hj/CMrgdvjTTxazbt1heC6BCw7j83kLHZrCO1u/NI/I5SiE57HLDhq8zN9aChkDBx9r3EVfQgE+FDWyoi8h/gKv8qOr3L5TWlLwJbduRpMucs+IFievAHRkPkOmErHDIo1e8xFyCVGrOgrkbHB42lQiUkUE69ZRc2KERZ5wGBd5lSUiWDmixRsXePriZNhUInhX8KZwfvun5olkOjJ7mS8ziYu85mXy1pMtIqQ60p1bRLp/E4GIZy0iJDqi5ekSi8hMYiL4BorFRh4RIbl13p97fB4zScQeFAt1nHn6Q3NrkoUG/Qd83jZJwB74Xq0fESF9RgvXT8DAsSUOS/zzu4m31r+YO0cWgL+mK6kolgokZAAAAABJRU5ErkJggg==) !important;
  background-size: 70%;
}

.swiper-button-prev {
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAIeSURBVEhLxZXPaxNREMe/sxvqP+CPqvUgqAVpz8EfSZtUPCim0mIv/n2epGINBcGmRaUNOehNaLGK1ipai6An2+7uOC9vstrdTfI2iH4g2ZlZ2E8m771Z/CtIrwPBD8tngPCxhLvY/1mjuRff7Z00A4t4vjgCv7Ai4TlbQQtDwXW60fqh+SE8veaCFyeHExJDEXv+XY1T5Ba1JQfBsoR/SgzrKAzd1ziFr1cneKF4AiGM5KKtxGzIIkxR7fkXzVM4rxEvXDkFxhMJk5JNecoETa9+0jwTJ1G7Ey48lXDUVmLey59SotvPPmjelb5rxA8uHxdJQ8KkZAshVV0khp4dtSUeGcmYrcR8BkUlmm5uat6Xrh31kMiCe1N5JIbMjqzEW5JoXEsdduSnVam2+kpzZ7I78mgxJSF8BaJrg0gM+Q6sjwONcpMtivimfK/bRGEcQ+g15Dwld58TmSKaWduRh1Yl3LCVGHNoG1wvn9fcmd7be750En5khmeiC9pGGF2l2TU5sG70nQw6esx8OywjvJVPRTbHllZ64jiCusiAN7JDKi7TwUlkUJn5Gy/YSszfG6oduH7pNAJaAVFyM8hrIhBZq+trItc5olvNjyhwBcyvtdRhVAbvEtcnj2qeIt+BFdqyKDRb/52txIwh2L+jcYrcIgPNtrbBYUXC39ub+SWOhPc0S5FrjZLwo/JZ6a4O4l3s8QzNNb/prf8F8AsLh69FRa2nngAAAABJRU5ErkJggg==) !important;
  background-size: 70%;
}

.text {
  background-color: #fafafa;
  float: right;
  color: #636363;
  height: 320px;
  padding-right: 30px;
  padding-left: 40px;
}

img.responsive {
  height: auto;
  width: 100%;
}

img.responsive_mob {
  float: left;
  height: 256px;
  width: 424px;
  display: none;
}

#column1,
#column2 {
  margin-right: 1%;
  width: 50%;
  display: flex;
}

#column2 p {
  margin-bottom: 0;
  overflow: hidden;
  min-height: 170px;
  font-size: 14px;
  text-align: justify;
  line-height: 1.6;
}

#column2 p a {
  cursor: pointer;
  color: rgb(238, 146, 13);
}

#columnmid {
  display: none;
  overflow: hidden;
  margin-left: 50px;
  padding-right: 30px;
  width: 480px;
  height: 120px;
  text-align: justify;
  line-height: 1.7;
  margin-right: 60px;
}

.swiper-pagination {
  bottom: 0;
  padding: 10px;
  z-index: 99 !important;
}

.slider .swiper-wrapper {
  -webkit-padding-start: 0px;
          padding-inline-start: 0px;
}

.swiper-button-next::after {
  display: none;
}

.swiper-button-prev::after {
  display: none;
}

.swiper-slide {
  background-color: #fafafa;
  font-size: 18px;
  line-height: 1.8;
  box-sizing: border-box;
  padding-top: 1%;
  padding-bottom: 5%;
  /* padding-left: 5%; */
}

.slider .swiper-slide {
  font-size: 18px;
  line-height: 1.8;
  box-sizing: border-box;
  margin: auto;
  padding-left: 0;
}

.swiper-pagination .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  background-color: #cfcfcf;
  opacity: 1;
}

.swiper-pagination .swiper-pagination-bullet-active {
  background-color: #ffb03b;
}

.box {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background-color: rgb(248, 248, 248);
  border-radius: 5px;
}

@media (min-width: 1300px) and (max-width: 2560px) {
  #column2 p {
    font-size: 1.1vw;
  }
}

@media screen and (max-width: 1300px) {
  .swiper-container {
    width: 1000px;
  }
  .sliderheading {
    text-align: center;
  }
}

@media screen and (max-width: 1000px) {
  .wrapper {
    flex-direction: column;
  }
  #column1,
  #column2 {
    width: 90%;
  }
  img.responsive {
    width: 100%;
  }
  #column2 p {
    line-height: 1.4;
  }
  .sliderheading {
    font-size: 20px;
    padding-bottom: 0px;
  }
  .swiper-container {
    width: 640px;
  }
}

@media screen and (max-width: 700px) {
  #column1,
  #column2 {
    width: 100%;
  }
  .wrapper {
    padding: 0%;
    display: block;
    margin: auto;
    height: 100%;
  }
  img.responsive {
    display: none;
  }
  img.responsive_mob {
    height: auto;
    width: 60%;
    display: block;
    padding: 0%;
    margin: auto;
  }
  #column1 {
    width: 100%;
    margin: 0%;
    padding: 0%;
    margin: auto;
    display: flex;
  }
  #column2 p {
    width: 100%;
    margin: auto;
    padding: 15px;
    padding-bottom: 50px;
  }
  .slider .swiper-pagination .swiper-pagination-bullet {
    width: 7px;
    height: 7px;
    background-color: #cfcfcf;
    opacity: 1;
  }
  .slider .swiper-pagination .swiper-pagination-bullet-active {
    background-color: #ffb03b;
  }
  .sliderheading {
    padding: 0%;
    margin: auto;
    text-align: center;
  }
  .slider .swiper-container {
    width: 95%;
    height: auto;
    margin: auto;
  }
  .slider .swiper-slide {
    font-size: 18px;
    line-height: 1.8;
    box-sizing: border-box;
    padding-top: 1%;
    padding-bottom: 5%;
    padding: 0px;
    margin: auto;
    margin-top: 10px;
  }
  .swiper-button-next {
    background-repeat: no-repeat;
    background-position: center;
    background-image: none !important;
    background-size: 60%;
  }
  .swiper-button-prev {
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    background-image: none !important;
    background-size: 60%;
  }
}

.Pricing .card {
  --color1: hsl(36, 90%, 40%);
  --color1-light: hsl(36, 90%, 35%);
  --color2: #f4ebd5;
  --card-width: min(16rem, 30vw);
  --card-height: min(20rem, 28vw);
  --head1: clamp(0.6rem, 3vw, 0.9rem);
  --head2: clamp(1.1rem, 4vw, 1.6rem);
  --head3: clamp(0.7rem, 3vw, 0.85rem);
  --btn-font: clamp(0.9rem, 3vw, 1.1rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* margin: 0 0.05rem; */
  width: var(--card-width);
  height: var(--card-height);
  background: linear-gradient(200deg, var(--color2) 5%, var(--color1));
  /* background: var(--color2); */

  border-radius: 0;
  margin: 0;
  border: none;
}
/* .card:nth-child(n + 2) {
  border-left: none;
} */

/* CARD TOOL-TIP */
.pricing_section .tp {
  left: 5% !important;
  margin-top: 0% !important;
  width: calc(var(--card-width) * 0.9) !important;
  padding: 0.2rem 0.3rem;
  display: flex;
  flex-direction: column;
  background: white;
  color: black;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
  /* margin-top: 3% !important; */
}
.pricing_section .tp_head1 {
  font-weight: bold;
  font-size: clamp(0.5rem, 3vw, 0.8rem);
}
.pricing_section .tp_head2 {
  font-size: clamp(0.4rem, 3vw, 0.7rem);
}

/* CARD INFO */
.pricing_section .card .name {
  width: 80%;
  height: min(3rem, 8vh);
  font-size: var(--head1);
  font-weight: 600;
  margin-bottom: 0.1rem;
  text-align: center;
  /* border: 1px solid red; */
  display: flex;
  justify-content: center;
  align-items: flex-end;
  overflow: auto;
}
.pricing_section .card .price {
  font-size: var(--head2);
  font-weight: 700;
  color: var(--color1-light);
}

.pricing_section .card_button {
  margin-top: 5px;
}

.pricing_section .specs {
  /* margin-top: 1vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}
.pricing_section .benefits {
  border-bottom: 1px dashed black;
  font-size: var(--head3);
}

.pricing_section .choose_btn {
  margin-top: min(4rem, 3vh);
  padding: 0.2rem;
  width: 40%;
  height: min(2.5rem, 6vh);

  border-radius: 25px;
  border: 3px solid var(--color1);
  box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.25);
  background: white;

  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--head1);
}

.pricing_section .card.idle {
  -webkit-transform: scale(1, 1);
          transform: scale(1, 1);
}
.pricing_section .card.choosed {
  -webkit-transform: scale(1, 1.12);
          transform: scale(1, 1.12);
  /* transition-duration: 0ms; */
  z-index: 2 !important;
  /* transform-origin: center; */

  box-shadow: 0px 0px 10px 1px var(--color1) !important;
}

.pricing_section .choose_btn.choosed {
  color: var(--color2);
  background: var(--color1);
  box-shadow: 0 0px 5px var(--color2);
  padding: 0.2rem !important;
}
.pricing_section .choose_btn.choosed:hover {
  color: var(--color1);
  background: var(--color2);
  box-shadow: 0 0px 5px var(--color1);
}

/* @media (min-width:) */

@media (min-width: 601px) and (max-width: 1024px) {
  .pricing_section .card {
    --card-width: min(15rem, 30vw);
    --card-height: fit-content;
    padding: 1rem 0;
  }
}

/* MOBILE */
@media (max-width: 600px) and (min-width: 481px) {
  .pricing_section .card.idle {
    -webkit-transform: scale(0.95, 0.95);
            transform: scale(0.95, 0.95);
  }
  .pricing_section .card.choosed {
    -webkit-transform: scale(1);
            transform: scale(1);

    box-shadow: 0px 0px 10px 2px var(--color1) !important;
  }

  .Pricing .carousel .card_wrapper {
    margin: 1rem;
  }

  .pricing_section .card {
    margin: 0.25rem;
    --card-width: min(17rem, 47vw);
    --card-height: max(20rem, 58vw);

    --head3: clamp(0.7rem, 4vw, 0.95rem);
    --btn-font: clamp(0.5rem, 2vw, 1rem);
  }
  .pricing_section .choose_btn.choosed {
    padding: 0 !important;
  }
}

/* @media (max-width: 480px) {
  .pricing_section .card .name {
    font-size: clamp(0.7rem, 3vw, 0.8rem);
  }
  .pricing_section .card {
    margin: 0.15rem;
    --card-width: min(18rem, 47vw);
    --card-height: min(20rem, 45vh);

    --head3: clamp(0.8rem, 1vw, 0.85rem);
    --btn-font: clamp(0.6rem, 2vw, 1.1rem);
    padding: 2vh 0;
    transform: scale(0.95);
  }
  .pricing_section .card.choosed {
    transform: scale(1);
  }
  .pricing_section .choose_btn {
    padding: 0.1rem 0.3rem;
    border: 3px solid var(--color1);
  }
} */

@media (max-width: 480px) {
  .pricing_section .card .name {
    max-height: 5vh;
    /* overflow-y: scroll; */
    /* border: 1px solid red; */
    font-size: clamp(0.7rem, 3vw, 0.8rem);
    padding: 0 0.1rem;
  }
  .pricing_section .card .name::-webkit-scrollbar {
    width: 0rem !important;
  }
  .pricing_section .card {
    margin: 0.15rem;
    --card-width: min(18rem, 47vw);
    --card-height: min(20rem, 38vh);

    --head3: clamp(0.8rem, 1vw, 0.85rem);
    --btn-font: clamp(0.6rem, 2vw, 1.1rem);
  }
  .pricing_section .card.idle {
    padding: 0.5vh 0;
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }
  .pricing_section .card.choosed {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  .pricing_section .choose_btn {
    padding: 0.1rem 0.3rem;
    border: 3px solid var(--color1);
    height: 5vh;
  }
}

@media (max-width: 600px) {
  /* CARD TOOL-TIP */

  .tp {
    left: 2% !important;
    margin-top: 5% !important;
    width: calc(var(--card-width) * 0.95) !important;
  }
  /* .specs:nth-child(5) {
    margin-top: -30% !important;
  } */
  .pricing_section .tp_head1 {
    font-weight: bold;
    font-size: clamp(0.5rem, 3vw, 0.7rem);
  }
  .pricing_section .tp_head2 {
    font-size: clamp(0.4rem, 3vw, 0.6rem);
  }
  .pricing_section .choose_btn.choosed {
    color: var(--color2);
    background: var(--color1);
    box-shadow: 0 0px 5px var(--color2);
    padding: 0rem;
  }
}

/* @media (max-width: 300px) {
  .pricing_section .card .name {
    max-height: 5vh;
    font-size: clamp(0.7rem, 3vw, 0.8rem);
    padding: 0 0.1rem;
  }
} */

/* .Pricing {
  border: 1px solid black;
} */
.pricing_section {
  position: relative;
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
}
.pricing_section a {
  text-decoration: none;
  color: black;
}
.pricing_section_cards {
  /* display: flex;
  position: relative;
  flex-direction: row; */

  background-color: grey;

  /* white-space: nowrap;
  width: 100%; */

  /* align-items: center;
  justify-content: flex-start; */
  /* overflow-x: scroll; */
}
.pricing_section .switch_container {
  /* border: 1px solid black; */
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: min(1vw, 1rem);
  gap: min(1vw, 1rem);
}
.pricing_section .switch_container_span {
  opacity: 0.7;
}

.pricing_section .carousel_title,
.pricing_section .table_title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: black;
}
.pricing_section .table_title {
  margin-top: min(5vh, 2rem);
  margin-bottom: min(2vh, 5rem);
}
.pricing_section .carousel_title .head1,
.pricing_section .table_title .head1 {
  font-size: clamp(1.5rem, 8vw, 2.2rem);
  font-weight: 700;
}
.pricing_section .carousel_title .head2 {
  font-size: clamp(0.8rem, 3vw, 1rem);
}

@media (max-width: 600px) {
  .pricing_section {
    padding: 2rem 2vw;
  }
  .pricing_section .switch_container {
    /* border: 1px solid black; */

    margin-top: 1rem;
  }
}
/* .slick-current {
  color: purple;
} */

.cc_slider {
  --color1: hsl(36, 90%, 40%);
  --color1-light: hsl(36, 90%, 35%);
  --color2: #f4ebd5;
  position: relative;
  width: 4rem;
  height: 2rem;
  padding: 0 0.1rem;
  cursor: pointer;
  transition: 0.4s;
  border: 2px solid var(--color1);
  border-radius: 25px;
}

.cc_slider::before {
  position: absolute;
  inset: 0;
  margin: auto 0;
  content: "";
  height: 1.5rem;
  width: 1.5rem;
  -webkit-transform: translateX(20%);
          transform: translateX(20%);
  transition: 0.4s;

  background: var(--color1);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  /* border: 1px solid rgb(2, 2, 2); */
}
.cc_slider.active::before {
  background: var(--color1);
}

.cc_slider.active:before {
  -webkit-transform: translateX(135%);
  transform: translateX(135%);
}

.cc_slider.round:before {
  border-radius: 50%;
}

@media (max-width: 600px) {
  .cc_slider {
    -webkit-transform: scale(0.75);
            transform: scale(0.75);
  }
}

.Pricing .carousel {
  --color1: #c0760a;
  --color2: #f4ebd5;
  padding: min(2rem, 1vh) 0;
  width: 80%;
  margin: auto;
  /* background: black; */
}
.Pricing .carousel .card_wrapper {
  margin: 2rem 0;
}
.Pricing .card_wrapper.mobile {
  display: flex !important;
  flex-direction: column;
  justify-content: flex-start !important;
  align-items: center;
  grid-gap: 0rem !important;
  gap: 0rem !important;
  margin-right: -0rem !important;
}

.slick-dots li {
  -webkit-transform: scale(2.5);
          transform: scale(2.5);
  margin-left: 1.5vw;
}
.slick-dots li button::before {
  color: var(--color1) !important;
}
.carousel .slick-list {
  width: 100%;
}

/* .slick-arrow.slick-prev,
.slick-arrow.slick-next {
  opacity: 0 !important;
}
.carousel:hover .slick-arrow.slick-prev,
.carousel:hover .slick-arrow.slick-next {
  opacity: 1 !important;
} */

/* .carousel .slick-track {
  margin-left: 0;
} */
.slick-list,
.slick-track {
  touch-action: pan-y;
}
@media (min-width: 700px) {
  .slick-track {
    /* border: 1px solid black; */
    padding: 0 1vw;
    margin: 0 1vw;
  }
}
@media (min-width: 600px) and (max-width: 1200px) {
  .Pricing .carousel {
    padding: min(2rem, 1vh) 0;
    width: 90%;
    margin: auto;
    /* background: black; */
  }
}
.carousel .slick-arrow.slick-prev::before {
  font-family: "Font Awesome 5 Free";
  content: "\f053" !important;
  color: var(--color1) !important;
  font-weight: 900;
  font-size: clamp(1.2rem, 4vw, 2rem);
}

.carousel .slick-arrow.slick-next::before {
  font-family: "Font Awesome 5 Free";
  content: "\f054" !important;
  color: var(--color1) !important;
  font-weight: 900;
  font-size: clamp(1.2rem, 4vw, 2rem);
}

@media (max-width: 600px) {
  .Pricing .carousel {
    width: 100%;
  }
  .Pricing .carousel .card_wrapper {
    margin: 0.5rem 0;
    margin-left: -0.07rem;
  }
}

.p_table {
  --color1: #c2780a;
  --color1-light: hsl(36, 90%, 35%);
  --color1-light-shade: hsl(36, 71%, 91%);
  --color2: #f4ebd5;
  --first-col-size: max(13vw, 15rem);
  /* --sticky-selected-left: max(10vw, 6rem); */
  --sticky-selected-left: calc(var(--first-col-size) * 1);
  --sticky-selected-right: 0;
  --thead-height: max(10vw, 7rem);
  /* margin: 5vh 10vw 0; */

  margin: 0vh 10vw;
  padding: 0;

  overflow: scroll;
  scroll-behavior: smooth;
  height: 85vh;

  /* border: 1px solid black; */
}
.cbg {
  color: red;
}
/* FOR SCROLL BARS */
.p_table::-webkit-scrollbar {
  height: 0.5rem !important;
  width: 0rem !important;
}
/* ARROWS  */
.left-arrow,
.right-arrow {
  border: none;
  background: none;
  padding: 0;
  margin: 0;
}

.left-arrow i,
.right-arrow i {
  font-size: clamp(2rem, 4vw, 4rem);
  color: var(--color1);
}
.left-arrow i:hover,
.right-arrow i:hover {
  color: hsl(36, 85%, 63%);
}

.left-arrow.disable,
.right-arrow.disable {
  opacity: 0.2;
}

/* .p_table::-webkit-scrollbar-thumb {
  border-radius: 0;
} */

.p_table table {
  padding: 0;
  margin: 0;
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;
  /* border: 1px solid black; */
}
.p_table thead {
  position: -webkit-sticky;
  position: sticky;
  z-index: 2;
}
.p_table tbody {
  position: -webkit-sticky;
  position: sticky;
  z-index: 1;
}
.p_table thead tr {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  /* top: 5%; */
  background: white;
}
.p_table td {
  background: white;
}
.p_table tbody td {
  padding: 1.2vh 0.5vw;
  /* border-bottom: 1px solid;
  border-left: 1px solid;
  border-right: 1px solid; */
  text-align: center;
  z-index: 1;
  font-size: clamp(0.6rem, 3vw, 1rem);
}

.p_table thead td {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background: var(--color1-light-shade);
  z-index: 4;
  cursor: default;
  padding: 1vh min(2.5vw, 2rem);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  /* border: 1px solid black; */
  height: var(--thead-height);
}

.p_table thead td:first-child {
  position: -webkit-sticky;
  position: sticky;
  top: 6rem;
  left: 0%;

  background: white;
  z-index: 5 !important;
  width: var(--first-col-size);
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
}
.p_table tbody td:first-child {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 1;
  background: white;
  text-align: left;
  border: none;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  font-weight: 600;

  /* transform: translateY(15%); */
}
.p_table tbody td:first-child .services {
  position: absolute;
  inset: 0;
  z-index: 5 !important;
  margin: 0.4vw;
  cursor: default;
  display: flex;
  align-items: center;
}
/* tool tip */
.p_table tbody td:first-child .services.show::before {
  position: absolute;
  left: 0%;
  z-index: 5;
  -webkit-transform: scale(1, 0);
          transform: scale(1, 0);
  content: attr(data-i);
  width: -webkit-max-content;
  width: max-content;
  padding: 0.1rem 0.3rem;
  background: var(--color1-light);
  color: white;
}

.p_table tbody td:first-child .services.show:hover::before {
  -webkit-transform: scale(1, 1);
          transform: scale(1, 1);
  -webkit-transform-origin: top;
          transform-origin: top;
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
}

.p_table tbody td:first-child .services div {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: clamp(0.6rem, 3vw, 1.1rem);
  opacity: 1;
  transition: opacity 0.4s ease-out;
}
.p_table tbody td:first-child .services.show:hover div {
  opacity: 0;
  transition: opacity 0.2s ease-in;
}

.p_table thead td.choosed {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: var(--sticky-selected-left);
  right: var(--sticky-selected-right);
  z-index: 5;
  border: 3px solid var(--color1);
  border-bottom: none;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
}

.p_table tbody td.choosed {
  position: -webkit-sticky;
  position: sticky;
  z-index: 0;
  left: var(--sticky-selected-left);
  right: var(--sticky-selected-right);

  border-left: 3px solid var(--color1);
  border-right: 3px solid var(--color1);
}
.p_table tbody tr:last-child td.choosed {
  position: -webkit-sticky;
  position: sticky;
  left: var(--sticky-selected-left);
  right: var(--sticky-selected-right);
  z-index: 2;
  border-bottom: 3px solid var(--color1);
  border-left: 3px solid var(--color1);
  border-right: 3px solid var(--color1);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

/* --------------- */
/* INFO HEAD */
.p_table .info {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 0.2rem;
  gap: 0.2rem;
}
.p_table td .pt_name {
  position: relative;
  width: calc(var(--thead-height) * 0.6);

  height: calc(var(--thead-height) * 0.15);
  padding: 0.1rem;
  /* overflow-y: scroll; */
  text-align: center;
  font-size: clamp(0.6rem, 3vw, 0.9rem);
}
.p_table td .pt_name div {
  /* border: 1px solid black; */
  position: absolute;
  inset: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.p_table td.choosed .pt_name {
  font-weight: bold;
  /* font-size: clamp(0.5rem, 3vw, 0.8rem); */
}
/* tool tip */
.p_table td .pt_name.show::before {
  position: absolute;
  left: 0;
  z-index: 5;
  display: block;
  -webkit-transform: scale(1, 0);
          transform: scale(1, 0);
  content: attr(data-i);
  width: calc(var(--thead-height) * 0.6);
  padding: 0.1rem 0.3rem;
  background: var(--color1-light);
  color: white;
}

.p_table td .pt_name.show:hover::before {
  -webkit-transform: scale(1, 1);
          transform: scale(1, 1);
  -webkit-transform-origin: top;
          transform-origin: top;
  transition: -webkit-transform 0.2s ease-out;
  transition: transform 0.2s ease-out;
  transition: transform 0.2s ease-out, -webkit-transform 0.2s ease-out;
}

.p_table .pt_price {
  font-size: clamp(0.8rem, 3vw, 1.4rem);
  font-weight: 600;
  color: var(--color1);
}
.p_table .info button {
  margin-top: 1vh;
  border: none;
  border-radius: 25px;
  padding: 0rem 0.8rem;
  font-size: clamp(0.8rem, 3vw, 0.8rem);
  border: 1.5px solid var(--color1);
  background: white;
}
.p_table .info button:hover {
  background: var(--color1);
}
.p_table .info button:hover a {
  color: white;
}

@media (min-width: 601px) and (max-width: 1200px) {
  .p_table {
    --thead-height: max(12vw, 10rem);
  }
}

@media (max-width: 600px) {
  .p_table {
    height: 75vh;
  }
  /* .p_table thead tr {
    position: fixed;
  } */
  .p_table tbody td:first-child .services.show:hover::before {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
    /* animation: fadeOut 3s ease; */
  }
  .p_table tbody td:first-child .services.show:hover div {
    opacity: 1;
    transition: opacity 0.2s ease-in;
  }
  @-webkit-keyframes fadeOut {
    0% {
      opacity: 1;
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
    100% {
      opacity: 0;
      -webkit-transform: scale(1, 0);
              transform: scale(1, 0);
    }
  }
  @keyframes fadeOut {
    0% {
      opacity: 1;
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
    100% {
      opacity: 0;
      -webkit-transform: scale(1, 0);
              transform: scale(1, 0);
    }
  }

  .p_table {
    margin: 0vh 0vw;
    --first-col-size: min(25vw, 10rem);
  }
  .p_table::-webkit-scrollbar {
    height: 0rem !important;
  }
  .p_table thead td {
    padding: 0.5vh min(1vw, 1rem);
  }
  .p_table thead td:first-child {
    width: var(--first-col-size);
  }
  .p_table .info button {
    margin-top: 1vh;
    height: 2.5vh;
    width: 90%;
    border: none;
    border-radius: 25px;
    padding: 0 0.2rem;
    margin: auto;
    font-size: clamp(0.5rem, 3vw, 0.6rem);
    border: 1.5px solid var(--color1);
  }
  .p_table .pt_name {
    width: calc(var(--thead-height) * 0.7);
    max-height: calc(var(--thead-height) * 0.3);
    padding: 0 0.1rem;
    /* overflow-y: scroll;
    overflow-x: hidden; */
    text-align: center;
    font-size: clamp(0.4rem, 3vw, 0.7rem);
    color: rgb(0, 0, 0);
  }
  .p_table td.choosed .pt_name {
    font-weight: bold;
    /* font-size: clamp(0.4rem, 3vw, 0.6rem); */
  }

  .p_table td .pt_name.show::before {
    width: calc(var(--thead-height) * 0.6);
    padding: 0.1rem 0rem;
    background: var(--color1-light);
    color: white;
  }
}

/* @media (max-width: 600px) {
  .p_table td.choosed .pt_name {
    font-weight: bold;
    font-size: clamp(0.5rem, 3vw, 0.8rem);
  }
} */

#footer {
  background-color: #000;
  color: #fff;
  padding: 10px 0px;
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
}
.footer-policy {
  text-align: center;
}
.footer-policy span {
  margin: 15px;
}
.footer-contact-links {
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
  grid-gap: 8px;
  gap: 8px;
}
.footer-credits {
  text-align: center;
}

.footer-contact-links a {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: #ffba52;
  border-radius: 50%;
  height: 30px;
  width: 30px;
}

.footer-social-links {
  display: flex;
  align-items: center;
  grid-gap: 4px;
  gap: 4px;
}

.footer-contacts-options {
  display: flex;
  align-items: center;
  grid-gap: 4px;
  gap: 4px;
}

.footer-icons {
  fill: white;
}

@media only screen and (max-width: 600px) {
  #footer {
    font-size: 15px;
  }
  .footer-contact-links {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-gap: 8px;
    gap: 8px;
  }
  .footer-contact-links a {
    height: 25px;
    width: 25px;
  }
}

