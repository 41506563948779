.nav-services-dropdown .dropdown-menu.show {
  background-color: rgba(0, 0, 0, 0.8862745098039215);
  color: white;
  box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%);
}
.nav-services-dropdown .dropdown-menu.show {
  margin: 0;
  padding: 0;
}
.nav-services-dropdown .dropdown-menu.show li {
  font-size: 15px;
  transition: all 0.1s ease-in;
}
.nav-services-dropdown .dropdown-menu.show li a {
  display: block;
  padding: 6%;
  transition: all 0.1s ease-in;
}
.nav-services-dropdown .dropdown-menu.show li a:hover {
  color: black;
  background-color: rgb(255, 196, 81);
}
/* .nav-services-dropdown .dropdown-menu {
  margin: 0;
  padding: 0;
  display: block;
}
.nav-services-dropdown .dropdown-menu.show {
  margin: 0;
  padding: 0;
  display: block;
} */
@media only screen and (max-width: 900px) {
  .nav-services-dropdown .dropdown-menu.show {
    position: relative !important;
    transform: translate(10px, 0) !important;
    background-color: transparent;
  }
  .nav-services-dropdown .dropdown-menu.show li a {
    padding: 0;
    background-color: transparent;
  }
  .nav-services-dropdown .dropdown-menu.show li:hover {
    color: rgb(255, 196, 81);
    background-color: transparent;
  }
  .nav-services-dropdown .dropdown-menu.show li a:hover {
    color: rgb(255, 196, 81);
    background-color: transparent;
  }
}

@media only screen and (max-width: 600px) {
  .nav-services-dropdown {
    display: flex;
    flex-direction: column;
  }
  .nav-services-dropdown .dropdown-menu.show li {
    width: max-content;
  }
}

/* .dropdown {
  position: relative;
}
.dropdown-btn {
  cursor: pointer;
}
.dropdown-title {
  background: transparent;
  border: none;
  text-decoration: none;
  cursor: pointer;
  font: inherit;
  color: inherit;
}
.dropdown-title:after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-menu {
  list-style: none;
  padding-inline-start: 0px;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8862745098039215);
  z-index: 500;
  top: 30px;
  border-radius: 4px;
  width: 180px;
  opacity: 0;
  transform: translateY(-10px);
  transition: all 150ms ease-in-out;
  pointer-events: none;
}
.dropdown-menu li {
  display: none;
  padding: 0px;
  margin: 0px;
}

.dropdown-menu.active {
  opacity: 1;
  transform: translateY(0);
}
.dropdown-menu.active li {
  display: block;
}
.active li:hover {
  background-color: rgb(255, 196, 81);
}
.active li a {
  display: block;
  text-decoration: none;
  color: white;
  padding: 6%;
  pointer-events: auto;
}
.active li a:hover {
  color: black;
}
.dropdown-menu:hover {
  opacity: 1;
  transform: translateY(0);
}

.dropdown-menu li a {
  display: block;
  text-decoration: none;
  color: white;
  padding: 5px 10px;
  pointer-events: auto;
  font-weight: 600;
}

.dropdown-menu li a:hover {
  transition: all 0.2s ease-in-out;
  background-color: #ffc451;
  color: black;
}

@media only screen and (max-width: 900px) {
  .dropdown-title {
    margin-left: 0px;
    padding: 0px;
  }
  .dropdown-menu {
    position: relative;
    opacity: 1;
    background-color: inherit;
    top: 7px;
    padding: 4px 0px;
    left: 30px;
    display: none;
    transition: all 0.3s ease-in-out;
  }
  .dropdown-menu.active {
    display: block;
  }
} */
