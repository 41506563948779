#footer {
  background-color: #000;
  color: #fff;
  padding: 10px 0px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.footer-policy {
  text-align: center;
}
.footer-policy span {
  margin: 15px;
}
.footer-contact-links {
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
  gap: 8px;
}
.footer-credits {
  text-align: center;
}

.footer-contact-links a {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: #ffba52;
  border-radius: 50%;
  height: 30px;
  width: 30px;
}

.footer-social-links {
  display: flex;
  align-items: center;
  gap: 4px;
}

.footer-contacts-options {
  display: flex;
  align-items: center;
  gap: 4px;
}

.footer-icons {
  fill: white;
}

@media only screen and (max-width: 600px) {
  #footer {
    font-size: 15px;
  }
  .footer-contact-links {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }
  .footer-contact-links a {
    height: 25px;
    width: 25px;
  }
}
