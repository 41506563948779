@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap");
body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

button:focus {
  outline: none;
}

a {
  text-decoration: none;
  color: inherit;
}

::-webkit-scrollbar {
  width: 6px;
  background: transparent;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb {
  background: #e3a702;
  border-radius: 8px;
  border: 1px solid transparent;
}

/* for each section */
/* section {
  border: 1px solid blue;
} */
.section_container {
  padding: 4vh 0;
}
/* Title section eg-> Digital Art Portfolio------- (something like this) */
.section_head_container {
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin: 0 5vw 1vh;
}
.section_head_container .section-title {
  font-size: 0.9rem;
  font-weight: 500;
  padding: 0;

  letter-spacing: 2px;
  text-transform: uppercase;
  color: #aaa;
  font-family: "Poppins", sans-serif;
}
.section_head_container .section-title:after {
  content: "";
  width: 120px;
  height: 1px;
  display: inline-block;
  background: #ffde9e;
  margin-left: 0.6rem;
}
.section_head_container .section-heading {
  margin: 0;
  font-size: 2rem;
  font-weight: 700;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  color: #151515;
}
.section_head_container .section-description {
  font-size: 0.9rem;
  font-weight: 500;
  color: #212529;
  margin: 0px;
}

@media only screen and (max-width: 600px) {
  .section_head_container .section-title:after {
    width: 100px;
  }

  .section_head_container .section-heading {
    font-size: 25px;
  }
  .section_head_container .section-description {
    line-height: 20px;
  }
}
