/* .Pricing {
  border: 1px solid black;
} */
.pricing_section {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.pricing_section a {
  text-decoration: none;
  color: black;
}
.pricing_section_cards {
  /* display: flex;
  position: relative;
  flex-direction: row; */

  background-color: grey;

  /* white-space: nowrap;
  width: 100%; */

  /* align-items: center;
  justify-content: flex-start; */
  /* overflow-x: scroll; */
}
.pricing_section .switch_container {
  /* border: 1px solid black; */
  display: flex;
  align-items: center;
  justify-content: center;
  gap: min(1vw, 1rem);
}
.pricing_section .switch_container_span {
  opacity: 0.7;
}

.pricing_section .carousel_title,
.pricing_section .table_title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: black;
}
.pricing_section .table_title {
  margin-top: min(5vh, 2rem);
  margin-bottom: min(2vh, 5rem);
}
.pricing_section .carousel_title .head1,
.pricing_section .table_title .head1 {
  font-size: clamp(1.5rem, 8vw, 2.2rem);
  font-weight: 700;
}
.pricing_section .carousel_title .head2 {
  font-size: clamp(0.8rem, 3vw, 1rem);
}

@media (max-width: 600px) {
  .pricing_section {
    padding: 2rem 2vw;
  }
  .pricing_section .switch_container {
    /* border: 1px solid black; */

    margin-top: 1rem;
  }
}
/* .slick-current {
  color: purple;
} */
