@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.popUpOverlay {
  background-color: transparent;
  position: fixed;
  inset: 0;
  z-index: 10000;
}
.popUpRoot {
  color: white;
  width: 40%;
  height: 40%;
  z-index: 1000;
  position: absolute;
  backdrop-filter: blur(3px);
  background: rgb(0 0 0 / 5%);
  animation-name: fadeIn;
  animation-duration: 0.2s;
  animation-iteration-count: 1;
  left: 30%;
  /* left:70%; */
  top:20%;
  /* inset: 100%; */
}

.popUpBox {
  width: 100%;
  height: fit-content;
  position: fixed;
  display: flex;
  flex-direction: column;
  text-align: center;
  /* top: 0;
  bottom: 0;
  right: 0;
  left: 0; */
  /* margin: auto auto; */
  /* left:70%;
  top:50%; */
  border-radius: 16px;
  background-color: rgba(0, 0, 0, 0.8);
  border: 1px solid #5f4e2e;
}
.popUpBox h4,
.popUpBox h1 {
  font-family: "Raleway", sans-serif;
  font-weight: 500;
}
@media (max-width: 640px) {
  .popUpRoot {
    width: 95%;
    left:3%;
  }
}

.popUpImg {
  width: 50%;
  height: fit-content;
  object-fit: contain;
  margin: 0 auto;
}
