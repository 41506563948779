.graphics_image_viewer {
  --color1: #cda45e;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;

  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  /* display: flex;
  align-items: center;
  justify-content: center; */
  /* overflow-y: scroll; */
  border: 2px solid var(--color1);
  background: rgba(0, 0, 0, 0.8);
}
.graphics_img_viewer_container {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  animation: expand 0.8s ease-in-out;
}

.btn_close {
  cursor: pointer;
  position: absolute;
  z-index: 2;
  top: 3%;
  right: 2%;
  transform: scale(1.8);
  color: white;
  background: none;
  backdrop-filter: blur(2px);
}
.graphics_image {
  /* max-height: 80vh;
  max-width: 70vw; */
  /* object-fit: cover; */
  border: 1px solid var(--color1);
}
.des_container {
  position: absolute;
  inset: auto 0 0 0;

  /* border: 1px solid var(--color1); */
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.graphics_image_details {
  /* border: 1px solid var(--color1); */

  max-width: 50%;

  z-index: 12;
  transform: translateY(50%);
  color: white;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  transition: transform 0.3s ease-in-out;
  backdrop-filter: blur(2px);
  /* text-align: center; */
  padding: 1rem 0.8rem;
}
.graphics_image_viewer_title {
  color: white;

  display: flex;
  align-items: baseline;
  justify-content: center;
  gap: 0.5rem;

  margin-bottom: 15px;
  /* border: 1px solid red; */
}

.des_container:hover .graphics_image_details {
  transform: translateY(0%);
  transition: transform 0.3s ease-in-out;
}
.title {
  position: relative;
  max-width: 80%;
}
.graphics_image_details h3 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  font-size: clamp(1.1rem, 4vw, 1.3rem);
}
.graphics_image_details p {
  text-align: left;
  opacity: 0;
  line-height: 20px;
  font-size: 14px;
  transition: all 0.3s ease-in-out;
}
.graphics_image_details:hover p {
  opacity: 1;
  transition: all 0.3s ease-in-out;
}

.graphics_image_viewer_title img {
  cursor: pointer;
}

@keyframes scrollText {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-50%);
  }
}

@media only screen and (max-width: 600px) {
  .btn_close {
    cursor: pointer;
    position: absolute;
    z-index: 2;
    top: 3%;
    right: 5%;
    transform: scale(1.5);
    color: white;
    background: none;
    backdrop-filter: blur(2px);
  }
  .graphics_image_viewer_title img {
    height: 20px;
    width: 20px;
  }

  .graphics_image_details {
    min-width: 90%;
    max-width: 90%;
    transform: translateY(70%);
  }
}
