/* css for main div which enclosing the whole client section  */
/* padding is given for fixed navbar */

.job_card_container {
  --color1: hsl(36, 90%, 40%);
  --color1-light: hsl(36, 90%, 35%);
  --color2: #f4ebd5;
  --color3: rgb(231, 207, 163);

  --head1: clamp(1rem, 4vw, 1.1rem);
  --sub-head: clamp(0.8rem, 4vw, 0.9rem);
  --sub-txt: clamp(0.7rem, 4vw, 0.8rem);

  /* border: 1px solid red; */
  padding: 0;
  margin: 0 auto;
  margin-top: 2vh;
  width: 98%;
  --width: 25rem;
  /* margin-top: 50px; */
  display: grid;
  grid-template-columns: repeat(auto-fill, var(--width));
  grid-row-gap: 1vw;
  grid-column-gap: 1vw;
  justify-content: center;

  /* border: 1px solid black; */
  min-height: 30vh;

  /* width: 100vw; */
  /* overflow: hidden; */

  /* margin: 0 auto; */
}
/* CARD */

.job_card_container .showcase_item {
  /* text-align: left; */

  position: relative;
  border: none;
  color: rgb(0, 0, 0);
  width: var(--width);
  aspect-ratio: 1/0.8;
  box-shadow: 1px 1px 5px var(--color1);
  background: linear-gradient(200deg, var(--color2) 10%, var(--color1));
}
.showcase_item.selectCard {
  animation: fOSelect 10s ease-in-out;
}
@keyframes fOSelect {
  0% {
    border: 3px solid black;
  }
  100% {
    border: none;
  }
}
.showcase_item_body {
  position: absolute;
  inset: 0;
}
/* MAIN DEATAILS */
.job_card_container .main_details {
  position: absolute;
  inset: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 0.3rem;
  padding: 1rem;
}
.job_card_container .main_details .title {
  text-transform: uppercase;
  font-size: var(--head1);
  margin-bottom: 1vh;
  line-height: 120%;
}
.job_card_container .main_details .category2 {
  font-size: var(--sub-txt);
}
.job_card_container .main_details .category2 .head {
  font-size: var(--sub-head);
  font-weight: bold;
  margin-right: 0.2rem;
}
.job_card_container .main_details .desc {
  position: relative;
  width: calc(var(--width) * 0.8);
  height: calc(var(--width) * 0.4);
  overflow: hidden;
}

/* END OF MAIN DEATAILS */

.showcase_item .more {
  position: absolute;
  top: 3%;
  height: 84%;
  padding: 0 1rem;
  overflow-y: scroll;
}
/* #show_items::-webkit-scrollbar-track {
  background-color: rgb(255, 255, 255);
} */
.showcase_item .read {
  position: absolute;
  bottom: 1%;
  right: 10%;
  cursor: pointer;
}
.read:before {
  border: solid rgb(0, 0, 0);
  border-width: 0 3px 3px 0;
  content: "";
  display: block;
  margin-top: -4px;
  padding: 3px;
  position: absolute;
  right: -10px;
  top: 50%;
  transform: rotate(-45deg);
  border-color: var(--color2);
}

.showcase_item .apply_popover {
  position: absolute;
  bottom: 1%;
  left: 35%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.showcase_item .apply_popover--button {
  background: transparent;
  border: none;
  color: #000 !important;
  padding-bottom: 0 !important;
}
.apply_popover--links {
  backdrop-filter: blur(10px);
}

.share {
  position: absolute;
  left: 4%;
  bottom: 0.8%;
}
.share a {
  all: unset;
  cursor: pointer;
}
.share i {
  font-size: clamp(1.05rem, 3vw, 1.2rem);
  color: var(--color3);
}

/* END OF CARD */

/* @media only screen and (max-width: 1100px) {
  .clientcontent-main {
    margin-top: unset;
  }
}

@media only screen and (min-width: 581px) and (max-width: 1200px) {
  .handleitems {
    margin-top: 50px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 0px;
    row-gap: 0px;
    max-width: 99%;
    margin: 0 auto;
  }
  .Clientheading {
    font-family: "Merriweather", serif;
    font-weight: bolder;
    font-size: 1.6rem;
    position: relative;
    left: -40%;
    right: 650px;
    font-weight: 900;
    text-transform: uppercase;
    padding: 5% 5% 2% 5%;
    color: rgb(255, 255, 255);
  }
} */

html {
  scroll-behavior: smooth;
}
