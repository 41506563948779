.navbar-space {
  position: fixed;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  z-index: 95;
  background: transparent;
  /* background: red; */
}
.navbar-container {
  /* height: 60px; */
  position: fixed;
  top: 0 !important;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.85);
  width: 100vw;
  height: 3.5rem;
  transform-origin: top;
  transform: scale(1, 0);
  opacity: 0;
  transition: transform 0.1s ease-out, opacity 0.4s ease-out;
  /* border: 1px solid red; */
}
.navbar-container.show {
  transform: scale(1, 1);
  opacity: 1;
  transition: opacity 0.1s ease-in;
}
.navbar {
  display: flex;
  justify-content: space-between;
  z-index: 100;
  width: 80%;
  margin: auto;
}
.navbar-container a:hover {
  color: rgb(255, 196, 81);
}
.hamburger-icon {
  display: none;
}
.navbar-logo {
  height: 40px;
  width: 50px;
}

.navbar-links-container {
  display: flex;
  align-items: center;
  /* border: 1px solid red; */
}
.navbar-links {
  color: white;
  list-style: none;
  display: flex;
  gap: 25px;
  font-size: 0.95rem;
  font-weight: 600;
  height: fit-content;
  transition: all 0.3s;
}
.navbar-links a:hover {
  color: rgb(243, 193, 93);
}
.navbar-links-mobile {
  display: none;
}
.navbar-controls {
  display: flex;
  align-items: center;
  gap: 15px;
  /* border: 1px solid red; */
  /* margin-right: min(4vw, 4rem); */
}
.navbar-icon {
  /* stroke: yellow; */
  fill: rgb(243, 193, 93);
  /* outline: yellow; */
  display: inline-block;
  height: 28px;
  width: 28px;
  cursor: pointer;
}

@media only screen and (max-width: 900px) {
  .navbar-links {
    display: none;
  }

  .navbar-links-mobile {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 0px 120px 20px;
    position: absolute;
    top: -300px;
    width: 100%;
    left: 0;
    z-index: 10 !important;
    background-color: inherit;
    color: white;
    list-style: none;
    opacity: 0;
    transition: top 0.8s ease-in-out, opacity 0.2s ease;
  }

  .navbar-links-mobile.active {
    opacity: 1;
    top: 44px;
    transition: top 0.3s ease-in-out, opacity 0.3s ease-in-out 0.2s;
  }

  .navbar-links-mobile li {
    font-size: 0.95rem;
    font-weight: 600;
    padding: 0.25rem 1.5rem;
  }

  .hamburger-icon {
    display: inline-block;
    margin-right: 20vh;
    cursor: pointer;
  }
  .hamburger-icon svg {
    fill: rgb(250, 197, 128);
    width: 32px;
    height: 32px;
  }
}

@media only screen and (max-width: 600px) {
  .navbar {
    width: 95%;
  }
  .hamburger-icon {
    margin-right: 15px;
  }
  .navbar-links-mobile {
    padding-left: 30px;
  }
}
