.Pricing .carousel {
  --color1: #c0760a;
  --color2: #f4ebd5;
  padding: min(2rem, 1vh) 0;
  width: 80%;
  margin: auto;
  /* background: black; */
}
.Pricing .carousel .card_wrapper {
  margin: 2rem 0;
}
.Pricing .card_wrapper.mobile {
  display: flex !important;
  flex-direction: column;
  justify-content: flex-start !important;
  align-items: center;
  gap: 0rem !important;
  margin-right: -0rem !important;
}

.slick-dots li {
  transform: scale(2.5);
  margin-left: 1.5vw;
}
.slick-dots li button::before {
  color: var(--color1) !important;
}
.carousel .slick-list {
  width: 100%;
}

/* .slick-arrow.slick-prev,
.slick-arrow.slick-next {
  opacity: 0 !important;
}
.carousel:hover .slick-arrow.slick-prev,
.carousel:hover .slick-arrow.slick-next {
  opacity: 1 !important;
} */

/* .carousel .slick-track {
  margin-left: 0;
} */
.slick-list,
.slick-track {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}
@media (min-width: 700px) {
  .slick-track {
    /* border: 1px solid black; */
    padding: 0 1vw;
    margin: 0 1vw;
  }
}
@media (min-width: 600px) and (max-width: 1200px) {
  .Pricing .carousel {
    padding: min(2rem, 1vh) 0;
    width: 90%;
    margin: auto;
    /* background: black; */
  }
}
.carousel .slick-arrow.slick-prev::before {
  font-family: "Font Awesome 5 Free";
  content: "\f053" !important;
  color: var(--color1) !important;
  font-weight: 900;
  font-size: clamp(1.2rem, 4vw, 2rem);
}

.carousel .slick-arrow.slick-next::before {
  font-family: "Font Awesome 5 Free";
  content: "\f054" !important;
  color: var(--color1) !important;
  font-weight: 900;
  font-size: clamp(1.2rem, 4vw, 2rem);
}

@media (max-width: 600px) {
  .Pricing .carousel {
    width: 100%;
  }
  .Pricing .carousel .card_wrapper {
    margin: 0.5rem 0;
    margin-left: -0.07rem;
  }
}
