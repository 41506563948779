.header-icon-box {
  border: 1px solid rgba(255, 255, 255, 0.342);
  height: 130px;
  width: 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* padding: 10px 0px 0px; */
  transition: all 0.3s ease-in-out;
}
.header-icon-box:hover {
  border-color: #ffc451;
}
.header-icon-box img {
  height: 50px;
  width: 50px;
}

.header-icon-box h3 {
  color: white;
  cursor: pointer;
  font-size: 20px;
  font-weight: 500;
  margin: 5pxpx 10px;
  line-height: 25px;
  transition: all 0.3s ease-in-out;
}
.header-icon-box h3:hover {
  color: #ffc451;
}
