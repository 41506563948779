.Pricing .card {
  --color1: hsl(36, 90%, 40%);
  --color1-light: hsl(36, 90%, 35%);
  --color2: #f4ebd5;
  --card-width: min(16rem, 30vw);
  --card-height: min(20rem, 28vw);
  --head1: clamp(0.6rem, 3vw, 0.9rem);
  --head2: clamp(1.1rem, 4vw, 1.6rem);
  --head3: clamp(0.7rem, 3vw, 0.85rem);
  --btn-font: clamp(0.9rem, 3vw, 1.1rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* margin: 0 0.05rem; */
  width: var(--card-width);
  height: var(--card-height);
  background: linear-gradient(200deg, var(--color2) 5%, var(--color1));
  /* background: var(--color2); */

  border-radius: 0;
  margin: 0;
  border: none;
}
/* .card:nth-child(n + 2) {
  border-left: none;
} */

/* CARD TOOL-TIP */
.pricing_section .tp {
  left: 5% !important;
  margin-top: 0% !important;
  width: calc(var(--card-width) * 0.9) !important;
  padding: 0.2rem 0.3rem;
  display: flex;
  flex-direction: column;
  background: white;
  color: black;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
  /* margin-top: 3% !important; */
}
.pricing_section .tp_head1 {
  font-weight: bold;
  font-size: clamp(0.5rem, 3vw, 0.8rem);
}
.pricing_section .tp_head2 {
  font-size: clamp(0.4rem, 3vw, 0.7rem);
}

/* CARD INFO */
.pricing_section .card .name {
  width: 80%;
  height: min(3rem, 8vh);
  font-size: var(--head1);
  font-weight: 600;
  margin-bottom: 0.1rem;
  text-align: center;
  /* border: 1px solid red; */
  display: flex;
  justify-content: center;
  align-items: flex-end;
  overflow: auto;
}
.pricing_section .card .price {
  font-size: var(--head2);
  font-weight: 700;
  color: var(--color1-light);
}

.pricing_section .card_button {
  margin-top: 5px;
}

.pricing_section .specs {
  /* margin-top: 1vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}
.pricing_section .benefits {
  border-bottom: 1px dashed black;
  font-size: var(--head3);
}

.pricing_section .choose_btn {
  margin-top: min(4rem, 3vh);
  padding: 0.2rem;
  width: 40%;
  height: min(2.5rem, 6vh);

  border-radius: 25px;
  border: 3px solid var(--color1);
  box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.25);
  background: white;

  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--head1);
}

.pricing_section .card.idle {
  transform: scale(1, 1);
}
.pricing_section .card.choosed {
  transform: scale(1, 1.12);
  /* transition-duration: 0ms; */
  z-index: 2 !important;
  /* transform-origin: center; */

  box-shadow: 0px 0px 10px 1px var(--color1) !important;
}

.pricing_section .choose_btn.choosed {
  color: var(--color2);
  background: var(--color1);
  box-shadow: 0 0px 5px var(--color2);
  padding: 0.2rem !important;
}
.pricing_section .choose_btn.choosed:hover {
  color: var(--color1);
  background: var(--color2);
  box-shadow: 0 0px 5px var(--color1);
}

/* @media (min-width:) */

@media (min-width: 601px) and (max-width: 1024px) {
  .pricing_section .card {
    --card-width: min(15rem, 30vw);
    --card-height: fit-content;
    padding: 1rem 0;
  }
}

/* MOBILE */
@media (max-width: 600px) and (min-width: 481px) {
  .pricing_section .card.idle {
    transform: scale(0.95, 0.95);
  }
  .pricing_section .card.choosed {
    transform: scale(1);

    box-shadow: 0px 0px 10px 2px var(--color1) !important;
  }

  .Pricing .carousel .card_wrapper {
    margin: 1rem;
  }

  .pricing_section .card {
    margin: 0.25rem;
    --card-width: min(17rem, 47vw);
    --card-height: max(20rem, 58vw);

    --head3: clamp(0.7rem, 4vw, 0.95rem);
    --btn-font: clamp(0.5rem, 2vw, 1rem);
  }
  .pricing_section .choose_btn.choosed {
    padding: 0 !important;
  }
}

/* @media (max-width: 480px) {
  .pricing_section .card .name {
    font-size: clamp(0.7rem, 3vw, 0.8rem);
  }
  .pricing_section .card {
    margin: 0.15rem;
    --card-width: min(18rem, 47vw);
    --card-height: min(20rem, 45vh);

    --head3: clamp(0.8rem, 1vw, 0.85rem);
    --btn-font: clamp(0.6rem, 2vw, 1.1rem);
    padding: 2vh 0;
    transform: scale(0.95);
  }
  .pricing_section .card.choosed {
    transform: scale(1);
  }
  .pricing_section .choose_btn {
    padding: 0.1rem 0.3rem;
    border: 3px solid var(--color1);
  }
} */

@media (max-width: 480px) {
  .pricing_section .card .name {
    max-height: 5vh;
    /* overflow-y: scroll; */
    /* border: 1px solid red; */
    font-size: clamp(0.7rem, 3vw, 0.8rem);
    padding: 0 0.1rem;
  }
  .pricing_section .card .name::-webkit-scrollbar {
    width: 0rem !important;
  }
  .pricing_section .card {
    margin: 0.15rem;
    --card-width: min(18rem, 47vw);
    --card-height: min(20rem, 38vh);

    --head3: clamp(0.8rem, 1vw, 0.85rem);
    --btn-font: clamp(0.6rem, 2vw, 1.1rem);
  }
  .pricing_section .card.idle {
    padding: 0.5vh 0;
    transform: scale(0.95);
  }
  .pricing_section .card.choosed {
    transform: scale(1);
  }
  .pricing_section .choose_btn {
    padding: 0.1rem 0.3rem;
    border: 3px solid var(--color1);
    height: 5vh;
  }
}

@media (max-width: 600px) {
  /* CARD TOOL-TIP */

  .tp {
    left: 2% !important;
    margin-top: 5% !important;
    width: calc(var(--card-width) * 0.95) !important;
  }
  /* .specs:nth-child(5) {
    margin-top: -30% !important;
  } */
  .pricing_section .tp_head1 {
    font-weight: bold;
    font-size: clamp(0.5rem, 3vw, 0.7rem);
  }
  .pricing_section .tp_head2 {
    font-size: clamp(0.4rem, 3vw, 0.6rem);
  }
  .pricing_section .choose_btn.choosed {
    color: var(--color2);
    background: var(--color1);
    box-shadow: 0 0px 5px var(--color2);
    padding: 0rem;
  }
}

/* @media (max-width: 300px) {
  .pricing_section .card .name {
    max-height: 5vh;
    font-size: clamp(0.7rem, 3vw, 0.8rem);
    padding: 0 0.1rem;
  }
} */
