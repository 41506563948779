.cc_slider {
  --color1: hsl(36, 90%, 40%);
  --color1-light: hsl(36, 90%, 35%);
  --color2: #f4ebd5;
  position: relative;
  width: 4rem;
  height: 2rem;
  padding: 0 0.1rem;
  cursor: pointer;

  -webkit-transition: 0.4s;
  transition: 0.4s;
  border: 2px solid var(--color1);
  border-radius: 25px;
}

.cc_slider::before {
  position: absolute;
  inset: 0;
  margin: auto 0;
  content: "";
  height: 1.5rem;
  width: 1.5rem;
  transform: translateX(20%);
  -webkit-transition: 0.4s;
  transition: 0.4s;

  background: var(--color1);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  /* border: 1px solid rgb(2, 2, 2); */
}
.cc_slider.active::before {
  background: var(--color1);
}

.cc_slider.active:before {
  -webkit-transform: translateX(135%);
  -ms-transform: translateX(135%);
  transform: translateX(135%);
}

.cc_slider.round:before {
  border-radius: 50%;
}

@media (max-width: 600px) {
  .cc_slider {
    transform: scale(0.75);
  }
}
