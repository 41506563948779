.p_table {
  --color1: #c2780a;
  --color1-light: hsl(36, 90%, 35%);
  --color1-light-shade: hsl(36, 71%, 91%);
  --color2: #f4ebd5;
  --first-col-size: max(13vw, 15rem);
  /* --sticky-selected-left: max(10vw, 6rem); */
  --sticky-selected-left: calc(var(--first-col-size) * 1);
  --sticky-selected-right: 0;
  --thead-height: max(10vw, 7rem);
  /* margin: 5vh 10vw 0; */

  margin: 0vh 10vw;
  padding: 0;

  overflow: scroll;
  scroll-behavior: smooth;
  height: 85vh;

  /* border: 1px solid black; */
}
.cbg {
  color: red;
}
/* FOR SCROLL BARS */
.p_table::-webkit-scrollbar {
  height: 0.5rem !important;
  width: 0rem !important;
}
/* ARROWS  */
.left-arrow,
.right-arrow {
  border: none;
  background: none;
  padding: 0;
  margin: 0;
}

.left-arrow i,
.right-arrow i {
  font-size: clamp(2rem, 4vw, 4rem);
  color: var(--color1);
}
.left-arrow i:hover,
.right-arrow i:hover {
  color: hsl(36, 85%, 63%);
}

.left-arrow.disable,
.right-arrow.disable {
  opacity: 0.2;
}

/* .p_table::-webkit-scrollbar-thumb {
  border-radius: 0;
} */

.p_table table {
  padding: 0;
  margin: 0;
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;
  /* border: 1px solid black; */
}
.p_table thead {
  position: sticky;
  z-index: 2;
}
.p_table tbody {
  position: sticky;
  z-index: 1;
}
.p_table thead tr {
  position: sticky;
  top: 0;
  /* top: 5%; */
  background: white;
}
.p_table td {
  background: white;
}
.p_table tbody td {
  padding: 1.2vh 0.5vw;
  /* border-bottom: 1px solid;
  border-left: 1px solid;
  border-right: 1px solid; */
  text-align: center;
  z-index: 1;
  font-size: clamp(0.6rem, 3vw, 1rem);
}

.p_table thead td {
  position: sticky;
  top: 0;
  background: var(--color1-light-shade);
  z-index: 4;
  cursor: default;
  padding: 1vh min(2.5vw, 2rem);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  /* border: 1px solid black; */
  height: var(--thead-height);
}

.p_table thead td:first-child {
  position: sticky;
  top: 6rem;
  left: 0%;

  background: white;
  z-index: 5 !important;
  width: var(--first-col-size);
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
}
.p_table tbody td:first-child {
  position: sticky;
  left: 0;
  z-index: 1;
  background: white;
  text-align: left;
  border: none;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  font-weight: 600;

  /* transform: translateY(15%); */
}
.p_table tbody td:first-child .services {
  position: absolute;
  inset: 0;
  z-index: 5 !important;
  margin: 0.4vw;
  cursor: default;
  display: flex;
  align-items: center;
}
/* tool tip */
.p_table tbody td:first-child .services.show::before {
  position: absolute;
  left: 0%;
  z-index: 5;
  transform: scale(1, 0);
  content: attr(data-i);
  width: max-content;
  padding: 0.1rem 0.3rem;
  background: var(--color1-light);
  color: white;
}

.p_table tbody td:first-child .services.show:hover::before {
  transform: scale(1, 1);
  transform-origin: top;
  transition: transform 0.3s ease-out;
}

.p_table tbody td:first-child .services div {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: clamp(0.6rem, 3vw, 1.1rem);
  opacity: 1;
  transition: opacity 0.4s ease-out;
}
.p_table tbody td:first-child .services.show:hover div {
  opacity: 0;
  transition: opacity 0.2s ease-in;
}

.p_table thead td.choosed {
  position: sticky;
  top: 0;
  left: var(--sticky-selected-left);
  right: var(--sticky-selected-right);
  z-index: 5;
  border: 3px solid var(--color1);
  border-bottom: none;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  backdrop-filter: blur(10px);
}

.p_table tbody td.choosed {
  position: sticky;
  z-index: 0;
  left: var(--sticky-selected-left);
  right: var(--sticky-selected-right);

  border-left: 3px solid var(--color1);
  border-right: 3px solid var(--color1);
}
.p_table tbody tr:last-child td.choosed {
  position: sticky;
  left: var(--sticky-selected-left);
  right: var(--sticky-selected-right);
  z-index: 2;
  border-bottom: 3px solid var(--color1);
  border-left: 3px solid var(--color1);
  border-right: 3px solid var(--color1);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

/* --------------- */
/* INFO HEAD */
.p_table .info {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.2rem;
}
.p_table td .pt_name {
  position: relative;
  width: calc(var(--thead-height) * 0.6);

  height: calc(var(--thead-height) * 0.15);
  padding: 0.1rem;
  /* overflow-y: scroll; */
  text-align: center;
  font-size: clamp(0.6rem, 3vw, 0.9rem);
}
.p_table td .pt_name div {
  /* border: 1px solid black; */
  position: absolute;
  inset: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.p_table td.choosed .pt_name {
  font-weight: bold;
  /* font-size: clamp(0.5rem, 3vw, 0.8rem); */
}
/* tool tip */
.p_table td .pt_name.show::before {
  position: absolute;
  left: 0;
  z-index: 5;
  display: block;
  transform: scale(1, 0);
  content: attr(data-i);
  width: calc(var(--thead-height) * 0.6);
  padding: 0.1rem 0.3rem;
  background: var(--color1-light);
  color: white;
}

.p_table td .pt_name.show:hover::before {
  transform: scale(1, 1);
  transform-origin: top;
  transition: transform 0.2s ease-out;
}

.p_table .pt_price {
  font-size: clamp(0.8rem, 3vw, 1.4rem);
  font-weight: 600;
  color: var(--color1);
}
.p_table .info button {
  margin-top: 1vh;
  border: none;
  border-radius: 25px;
  padding: 0rem 0.8rem;
  font-size: clamp(0.8rem, 3vw, 0.8rem);
  border: 1.5px solid var(--color1);
  background: white;
}
.p_table .info button:hover {
  background: var(--color1);
}
.p_table .info button:hover a {
  color: white;
}

@media (min-width: 601px) and (max-width: 1200px) {
  .p_table {
    --thead-height: max(12vw, 10rem);
  }
}

@media (max-width: 600px) {
  .p_table {
    height: 75vh;
  }
  /* .p_table thead tr {
    position: fixed;
  } */
  .p_table tbody td:first-child .services.show:hover::before {
    transform: scale(1, 1);
    /* animation: fadeOut 3s ease; */
  }
  .p_table tbody td:first-child .services.show:hover div {
    opacity: 1;
    transition: opacity 0.2s ease-in;
  }
  @keyframes fadeOut {
    0% {
      opacity: 1;
      transform: scale(1, 1);
    }
    100% {
      opacity: 0;
      transform: scale(1, 0);
    }
  }

  .p_table {
    margin: 0vh 0vw;
    --first-col-size: min(25vw, 10rem);
  }
  .p_table::-webkit-scrollbar {
    height: 0rem !important;
  }
  .p_table thead td {
    padding: 0.5vh min(1vw, 1rem);
  }
  .p_table thead td:first-child {
    width: var(--first-col-size);
  }
  .p_table .info button {
    margin-top: 1vh;
    height: 2.5vh;
    width: 90%;
    border: none;
    border-radius: 25px;
    padding: 0 0.2rem;
    margin: auto;
    font-size: clamp(0.5rem, 3vw, 0.6rem);
    border: 1.5px solid var(--color1);
  }
  .p_table .pt_name {
    width: calc(var(--thead-height) * 0.7);
    max-height: calc(var(--thead-height) * 0.3);
    padding: 0 0.1rem;
    /* overflow-y: scroll;
    overflow-x: hidden; */
    text-align: center;
    font-size: clamp(0.4rem, 3vw, 0.7rem);
    color: rgb(0, 0, 0);
  }
  .p_table td.choosed .pt_name {
    font-weight: bold;
    /* font-size: clamp(0.4rem, 3vw, 0.6rem); */
  }

  .p_table td .pt_name.show::before {
    width: calc(var(--thead-height) * 0.6);
    padding: 0.1rem 0rem;
    background: var(--color1-light);
    color: white;
  }
}

/* @media (max-width: 600px) {
  .p_table td.choosed .pt_name {
    font-weight: bold;
    font-size: clamp(0.5rem, 3vw, 0.8rem);
  }
} */
