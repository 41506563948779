@import url("https://fonts.googleapis.com/css2?family=Rubik&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,600;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cabin:wght@500&display=swap");

.cards_section {
  min-height: 30vh;
  font-size: clamp(0.9rem, 3vw, 1rem);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* form */
.md-stepper-horizontal {
  display: table;
  width: 100%;
  margin: 0 auto;
  background-color: #ffffff;
}
.md-stepper-horizontal .md-step {
  display: table-cell;
  position: relative;
  padding: 24px;
}
.md-stepper-horizontal .md-step:hover,
.md-stepper-horizontal .md-step:active {
  background-color: rgba(0, 0, 0, 0.04);
}
.md-stepper-horizontal .md-step:active {
  border-radius: 15% / 75%;
}
.md-stepper-horizontal .md-step:first-child:active {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.md-stepper-horizontal .md-step:last-child:active {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.md-stepper-horizontal .md-step:hover .md-step-circle {
  background-color: #757575;
}
.md-stepper-horizontal .md-step:first-child .md-step-bar-left,
.md-stepper-horizontal .md-step:last-child .md-step-bar-right {
  display: none;
}
.md-stepper-horizontal .md-step .md-step-circle {
  width: 34px;
  height: 34px;
  margin: 0 auto;
  background-color: #999999;
  border-radius: 50%;
  text-align: center;
  line-height: 30px;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  cursor: pointer;
}

.md-stepper-horizontal.yellow .md-step.active .md-step-circle {
  background-color: #ffc451;
}
.md-stepper-horizontal .md-step.active .md-step-circle {
  background-color: rgb(33, 150, 243);
}
.md-stepper-horizontal .md-step.done .md-step-circle:before {
  font-family: "FontAwesome";
  font-weight: 100;
  content: "\f00c";
}
.md-stepper-horizontal .md-step.done .md-step-circle *,
.md-stepper-horizontal .md-step.editable .md-step-circle * {
  display: none;
}
.md-stepper-horizontal .md-step.editable .md-step-circle {
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.md-stepper-horizontal .md-step.editable .md-step-circle:before {
  font-family: "FontAwesome";
  font-weight: 100;
  content: "\f040";
}
.md-stepper-horizontal .md-step .md-step-title {
  margin-top: 16px;
  font-size: 16px;
  font-weight: 600;
}
.md-stepper-horizontal .md-step .md-step-title,
.md-stepper-horizontal .md-step .md-step-optional {
  text-align: center;
  color: rgba(0, 0, 0, 0.26);
}
.md-stepper-horizontal .md-step.active .md-step-title {
  font-weight: 600;
  color: rgba(0, 0, 0, 0.87);
}
.md-stepper-horizontal .md-step.active.done .md-step-title,
.md-stepper-horizontal .md-step.active.editable .md-step-title {
  font-weight: 600;
}
.md-stepper-horizontal .md-step .md-step-optional {
  font-size: 12px;
}
.md-stepper-horizontal .md-step.active .md-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.md-stepper-horizontal .md-step .md-step-bar-left,
.md-stepper-horizontal .md-step .md-step-bar-right {
  position: absolute;
  top: 36px;
  height: 1px;
  border-top: 1px solid #dddddd;
}
.md-stepper-horizontal .md-step .md-step-bar-right {
  right: 0;
  left: 50%;
  margin-left: 20px;
}
.md-stepper-horizontal .md-step .md-step-bar-left {
  left: 0;
  right: 50%;
  margin-right: 20px;
}

.mycontainer {
  margin: auto;
}

.application-container {
  margin: auto;
}

.scroll_div {
  width: 100%;
  height: 500px;
  background-color: #26201f;
  overflow: scroll;
}

#exampleFormControlTextarea1::placeholder {
  font-style: italic;
  font-size: 12px;
  padding: 5px;
}

.checkboxes {
  margin-left: 10%;
}

.checkboxeslabel {
  margin-left: 18%;
}

.form-group .form-control {
  padding: 2px;
}

.feedback {
  font-size: 12px;
  color: red;
}

/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}
.cv-input {
  width: 100%;
  border: 1px solid #dc3545;
  border-radius: 4px;
  overflow: hidden;
  height: auto;
}
.cv-input p {
  float: left;
  display: inline;
  padding: 0%;
  margin: 0%;
  overflow: hidden;
  width: 70%;
}
.highlight-item {
  animation: border-animation 2s;
  animation-iteration-count: infinite;
  background-color: white;
}
@keyframes border-animation {
  0% {
    background-color: rgb(254, 200, 106);
  }
  100% {
    background-color: rgb(244, 236, 215);
  }
}
input[type="file"] {
  color: transparent !important;
  display: inline;
  width: 30%;
  width: 100px;
  float: right;
  border: none;
}
button[type="submit"]:hover {
  cursor: no-drop;
}

.cross {
  font-size: 25px;
  position: absolute;
  transform: translateY(-16px);
  left: 100%;
  color: gray;
  z-index: 1;
  cursor: pointer;
}

.filters {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
}
.seperate {
  display: none;
}

.section-title__2 h2 {
  font-size: 14px;
  font-weight: 500;
  padding: 0;
  line-height: 1px;
  margin: 0 0 5px 0;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #aaaaaa;
  font-family: "Poppins", sans-serif;
}

.section-title__2 h2::after {
  content: "";
  width: 120px;
  height: 1px;
  display: inline-block;
  background: #ffde9e;
  margin: 4px 10px;
}

.section-title__2 p {
  margin: 0;
  margin: 0;
  font-size: 36px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  color: #151515;
}
.categoryone button {
  background: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-size: 18px;
  font-weight: 600;
  line-height: 0;
  text-transform: uppercase;
  color: #444444;
  margin-bottom: 0px;
  transition: all 0.6s ease-in-out;
  border-radius: 3px;
  border: 0px;
  padding: 18px 10px;
  margin-right: 5px;
  margin-top: 8px;
}

.categorytwo button {
  color: #444;
  cursor: pointer;
  display: inline-block;
  padding: 4px 8px 4px 8px;
  font-size: 15px;
  font-weight: 700;
  line-height: 2;
  text-transform: uppercase;
  border: transparent;
  transition: all 0.8s ease-in-out;
  margin-right: 50px;
  border-radius: 8%;
  border: transparent;
  background-color: transparent;
}
.categoryone .button.is-checked {
  background-color: #ffc451;
}
.categorytwo .button.is-checked {
  transition: all 0.2s ease-in-out;
  color: #ee920d;
  border: transparent;
  /* padding: 2px 2px 2px 2px;
  margin-right: 35px; */
}
.categoryone .button:active {
  box-shadow: inset 0 1px 10px hsla(0, 0%, 0%, 0.8);
}
.categorytwo .button:active {
  transition: all 0.8s ease-in-out;
}

#snackbar {
  visibility: hidden; /* Hidden by default. Visible on click */
  min-width: 250px; /* Set a default minimum width */
  margin-left: 125px; /* Divide value of min-width by 2 */
  background-color: #333; /* Black background color */

  color: #fff; /* White text color */
  text-align: center; /* Centered text */
  border-radius: 2px; /* Rounded borders */
  padding: 16px; /* Padding */
  position: fixed; /* Sit on top of the screen */
  z-index: 1; /* Add a z-index if needed */
  /* Center the snackbar */
  bottom: 30px; /* 30px from the bottom */
}

/* Show the snackbar when clicking on a button (class added with JavaScript) */
#snackbar.show {
  visibility: visible; /* Show the snackbar */
  /* Add animation: Take 0.5 seconds to fade in and out the snackbar.
  However, delay the fade out process for 2.5 seconds */
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}
.vl {
  height: 300px;
  overflow: hidden;
  border-right: 1px solid rgb(69, 58, 56);
  border-left: 1px solid rgb(69, 58, 56);
  border-bottom: 16px solid #26201f;
  border-top: 1px solid rgb(69, 58, 56);
  padding: 4%;
}
.topic {
  padding-bottom: 5px;
}
.keywords {
  padding-bottom: 25px;
}
.padding-0 {
  padding-right: 0;
  padding-left: 0;
}
.closeee {
  position: absolute;
  z-index: 10;
  height: 30px;
  width: 30px;
  bottom: 30%;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
  border-radius: 8px;
  cursor: pointer;
}

.btnholder1 {
  position: absolute;
  bottom: 0;
  right: 0;
  margin-right: 0.93rem;
}

.btnholder1 button {
  margin: 0.5rem 0.5rem;
  margin-left: unset;
  background: hsla(0, 0%, 50%, 0.267);
  box-shadow: 0 8px 32px 0 rgb(31 38 135 / 37%);
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  border-radius: 3px;
  border: 1px solid hsla(0, 0%, 100%, 0.18);
  color: white;
}

.btnholder1 button:hover {
  backdrop-filter: blur(5px);
}

#cat__one,
#categorytwo {
  white-space: nowrap;
}

#categoryone::-webkit-scrollbar,
#categorytwo::-webkit-scrollbar {
  height: 0rem;
}

.vl .MuiSvgIcon-root {
  font-size: 2.5rem !important;
  cursor: pointer;
}

@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}

.clickhere {
  color: #ffc451;
  background-color: transparent;
  border: 0px;
}
.cross {
  font-size: 25px;
  position: absolute;
  transform: translateY(-16px);
  left: 100%;
  color: gray;
  z-index: 1;
  cursor: pointer;
}
.clickhere2 {
  color: black;
  background-color: transparent;
  border: 0px;
}

.carousel-control-prev-icon {
  position: absolute;
  left: 10%;
}
.carousel-control-next-icon {
  position: absolute;
  right: 10%;
}
.curimage {
  height: auto;
  position: relative;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.Modal {
  margin: auto;
  width: 600px;
  font-size: 1em;
  border-radius: 8px;
  background-color: #fff;
  padding: 25px;
  height: 600px;
  overflow-x: hidden;
  overflow-y: scroll;
  margin-top: 100px;
}

.Modal::-webkit-scrollbar {
  width: 7px;
  height: 50px;
  background: linear-gradient(#feeab3, transparent);
  border-radius: 8px;
}

.Modal::-webkit-scrollbar-thumb {
  background: linear-gradient(#e3a702, transparent);
  border-radius: 8px;
  border: 1px solid #e3a702;
}
.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(3, 0, 0, 0.9);
}

.Overlay1 {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
}

@media (min-width: 1920px) and (max-width: 2560px) {
  .Modal {
    margin-top: 150px;
  }
  .Modal2 {
    margin-top: 150px;
  }
}
.videomodal {
  display: flex;
}
.Modal4 {
  margin: auto;
  left: 0;
  right: 0;
  top: 1%;
  bottom: 0;
  margin: auto;
  position: absolute;
  z-index: 10000;
  width: 60%;
  background: #fff;
  border-radius: 8px;
  padding: 1.5%;
  height: 83vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.Overlay4 {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.15);
}

.projdesc {
  position: relative;
  line-height: 1.2;
  overflow-y: hidden;
  overflow-x: hidden;
  text-align: justify;
  line-height: 1.25;
  justify-content: center;
}

.projtit {
  text-align: center;
}

.projkey {
  text-align: center;
}
html {
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  -ms-font-smoothing: antialiased !important;
}
body {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  color: #555555;
}

.mycontainer {
  margin: auto;
}

.application-container {
  margin: auto;
}

.scroll_div {
  width: 100%;
  height: 500px;
  background-color: #26201f;
  overflow: scroll;
}

.joinourteam {
  /* background-color: #26201f; */
  height: auto;
  width: 100%;

  height: 70%;
  margin: auto;
}

.long {
  overflow-x: hidden;
  /* background-color: #26201f; */
  margin: auto;
  width: 100%;
}

.owlarousel__1 {
  width: calc(100% - 75.85px - 60px);
  margin: 0 30px;
}

.topic {
  color: aliceblue;
  font-family: "IBM Plex Sans", sans-serif;
  text-align: center;
  font-size: 20px;
}

.topic:hover {
  color: grey;
}
.keywords {
  color: #555555;
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 15px;
  margin-bottom: -20px;
}
.duration {
  color: #555555;
  font-size: 14px;
}

.long::-webkit-scrollbar {
  width: 8px;
  height: 10px;
  background: transparent;
  border-radius: 8px;
}
::-webkit-scrollbar {
  width: 6px;
  background: transparent;
  border-radius: 8px;
}
.long::-webkit-scrollbar-thumb {
  background: #ffc451;
  border-radius: 8px;
  border: 1px solid black;
}
::-webkit-scrollbar-thumb {
  background: #e3a702;
  border-radius: 8px;
  border: 1px solid transparent;
}
.jobdescription {
  color: white;
  font-size: 16px;
  text-align: justify;
  line-height: 1.2;
  justify-content: center;
  font-family: "Cabin", sans-serif;
  margin: auto;
}

.form-row {
  display: flex;
  margin-bottom: 1rem !important;
}

.Modal2 {
  margin-top: 100px;
  margin: auto;
  margin-top: 100px;
  font-size: 1em;
  border: 0px;
  background: #fff;
  border-radius: 8px;
  width: 500px;
  padding: 25px;
  height: 330px;
}

.Overlay2 {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(3, 0, 0, 0.92);
}

.close {
  background: none;
  border: none;
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}

.col {
  margin-right: 1rem !important;
}

.span-in-div {
  padding-top: 0px;
  margin-top: 0px;
}
.deadline {
  color: #555555;
  font-size: 14px;
  text-align: center;
  padding-bottom: 2px;
  margin-top: 0px;
  padding-top: 0px;
}
.applicationId {
  color: #555555;
  font-size: 14px;
  margin-top: 0px;
  padding-top: 0px;
  text-align: center;
  bottom: 0px;
}

@media only screen and (max-width: 600px) {
  .categorytwo .button.is-checked {
    color: #ee920d;
    border: transparent;
    /* padding: 2px 2px 2px 2px;
    margin-right: 35px; */
  }
  .cross {
    position: absolute;
    transform: translateY(-16px);
    left: 95%;
  }
  .categoryone button {
    color: black;
    cursor: pointer;
    display: inline-block;
    padding: 8px;
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
    text-transform: uppercase;
    color: #444444;
    transition: all 0.6s ease-in-out;
    border-radius: 3px;
    border: 0px;
  }
  .categorytwo button {
    color: black;
    cursor: pointer;
    display: inline-block;
    padding: 0px;
    font-size: 11px;
    font-weight: 600;
    line-height: 1;
    text-transform: uppercase;
    color: #444444;
    border-radius: 3px;
    border: 0px;
    margin-left: 0px;
    margin-right: 0px;
  }

  .topic {
    font-size: 16px;
  }

  .keywords {
    font-size: 13px;
  }
  .jobdescription {
    font-size: 15px;
  }

  .Modal4 {
    width: 95%;
  }

  .projkey {
    text-align: center;
    color: #ffc451;
  }
  .projdesc {
    font-size: 14px;
    padding: 5px;
  }

  #snackbar {
    margin: auto;
    min-width: 90px; /* Set a default minimum width */
    margin-left: -5px;
    font-size: 13px;

    /* Divide value of min-width by 2 */
    background-color: #333; /* Black background color */
    color: #fff; /* White text color */
    text-align: center; /* Centered text */
    border-radius: 2px; /* Rounded borders */
    padding: 16px; /* Padding */
    position: fixed; /* Sit on top of the screen */
    z-index: 1; /* Add a z-index if needed */
    /* Center the snackbar */
    bottom: 30px; /* 30px from the bottom */
  }
}

.jobdescription a:link {
  color: orange;
}
.jobdescription a:hover {
  color: white;
}

.notice {
  font-style: italic;
  font-size: 13px;
  text-align: center;
}
/*=====================================================================*/

#app-flters {
  padding: 0;
  margin: 0 auto 20px auto;
  list-style: none;
  text-align: center;
}

#app-flters li {
  cursor: pointer;
  display: inline-block;
  padding: 8px 15px 10px 15px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
  color: #444444;
  margin-bottom: 5px;
  transition: all 0.6s ease-in-out;
  border-radius: 8px;
}

#app-flters li:hover,
#app-flters li.filter-active {
  color: #151515;
  background: #ffc451;
}

#app-flters li:last-child {
  margin-right: 0;
}

.portfolio .portfolio-wrap .portfolio-info h4 {
  font-size: 20px;
  color: black;
  font-weight: 600;
}
.dropdown-cus {
  margin-left: 10px;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}

.dropdown-cus button {
  font-size: 13px;
}

.filter-button {
  border: none;
  font-size: 0.9rem;
}
.filter-button:hover {
  color: #b88700;
}
.hiringtext {
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: 500;
  text-align: justify;
  justify-content: center;
}

.slick-track {
  display: flex !important;
}

.slick-slide {
  width: unset !important;
}

/*--------------------------------------------------------------
# Portfolio
--------------------------------------------------------------*/

.portfolio-container {
  /* border: 1px solid red; */
  width: 80%;
  height: 100%;
}

/*--------------------------outer filter--------------------*/

/* @media only screen and (max-width: 323px) {
  .section-title__2 h2::after {
    width: 50px;
    height: 1px;

    margin: 4px 2px;
    margin-right: 0px;
  }
}
@media only screen and (max-width: 300px) {
  .section-title__2 h2::after {
    width: 40px;
    margin-left: 0px !important;
    margin-right: 0px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
} */
@media only screen and (max-width: 800px) and (min-width: 628px) {
  .portfolio .container {
    margin: auto;
    width: 330px;
  }
}
@media only screen and (max-width: 800px) {
  .application-container {
    width: 95%;
  }
}

@media only screen and (min-width: 1000px) and (max-width: 1400px) {
  .portfolio .container {
    margin: auto;
    width: 1600px;
  }
}

@media only screen and (max-width: 600px) {
  .noblog__txt {
    font-size: 0.3rem !important;
  }
  .cross {
    position: absolute;
    transform: translateY(-16px);
    left: 95%;
  }

  .topic {
    font-size: 16px;
  }

  .keywords {
    font-size: 13px;
  }
  .jobdescription {
    font-size: 15px;
  }

  .Modal {
    width: 94%;
    margin-left: 3%;
    margin-top: 20%;
    height: 82%;
  }
  .Modal2 {
    width: 80%;
    margin-left: 10%;
    margin-top: 40%;
  }

  .curimage {
    /* margin-top: 10px; */
    height: auto;
    width: 100%;
  }

  .projkey {
    text-align: center;
    color: #ffc451;
  }
  .projdesc {
    font-size: 14px;
    padding: 5px;
  }

  #snackbar {
    margin: auto;
    min-width: 90px; /* Set a default minimum width */
    margin-left: -5px;
    font-size: 13px;

    /* Divide value of min-width by 2 */
    background-color: #333; /* Black background color */
    color: #fff; /* White text color */
    text-align: center; /* Centered text */
    border-radius: 2px; /* Rounded borders */
    padding: 16px; /* Padding */
    position: fixed; /* Sit on top of the screen */
    z-index: 1; /* Add a z-index if needed */
    /* Center the snackbar */
    bottom: 30px; /* 30px from the bottom */
  }
}

@media only screen and (min-width: 1000px) and (max-width: 1200px) {
  .portfolio .container {
    margin: auto;
    width: 1600px;
  }
}
@media only screen and (max-width: 628px) {
  .categorytwo button {
    color: #444;
    cursor: pointer;
    display: inline-flex;

    justify-content: center;
    padding: 2px 3px 2px 3px;
    font-size: 13px;
    font-weight: 700;
    line-height: 2;
    text-transform: uppercase;
    border: transparent;
    margin-right: 0px;
    border-radius: 8%;
  }
  .categoryone .button.is-checked {
    background-color: #ffc451;
  }
  .categorytwo .button.is-checked {
    color: #ffc451;
    border: transparent;
  }
  .categoryone .button:active {
    box-shadow: inset 0 1px 10px hsla(0, 0%, 0%, 0.8);
  }
  .categorytwo .button:active {
    box-shadow: inset 0 1px 10px hsla(0, 0%, 0%, 0.8);
    border: transparent;
    background: transparent;
  }
  .section-title__2 p {
    font-size: 20px;
  }
  .section-title__2 {
    margin-bottom: 0px;
  }
  .filters {
    align-items: center;
  }
  .seperate {
    display: inline-block;
    font-size: larger;
    font-weight: 500;
    transform: translateY(-2px);
  }

  .portfolio #portfolio-flters {
    padding: 0;
    margin: 5px 5px 5px 5px;
    list-style: none;
    text-align: center;
  }

  .portfolio #portfolio-flters li {
    cursor: pointer;
    display: inline-block;
    padding: 8px 8px 8px 8px;
    font-size: 12px;
    font-weight: 600;
    line-height: 1em;
    text-transform: uppercase;
    color: #444444;
    margin-bottom: 0px;
    margin-top: 0px;
    transition: all 0.6s ease-in-out;
    border-radius: 3px;
  }

  .portfolio .container {
    margin: auto;
    width: 330px;
    height: auto;
  }
}

@media only screen and (max-width: 800px) and (min-width: 628px) {
  .categorytwo button {
    color: #444;
    cursor: pointer;
    display: inline-flex;

    justify-content: center;
    padding: 2px 4px 2px 4px;
    font-size: 13px;
    font-weight: 700;
    line-height: 2;
    text-transform: uppercase;
    border: transparent;
    margin-right: 30px;
    border-radius: 8%;
  }
  .categorytwo .button.is-checked {
    color: #ffc451;
    border: transparent;
  }
}

@media only screen and (max-width: 450px) {
  .Modal2 {
    height: 50%;
  }

  input[type="file"]::before {
    margin-right: 27%;
    padding-right: 0px;
  }

  .application-container {
    width: 95%;
    margin: auto;
    /* height: 640px; */
    /* height: 800px; */
  }
}
@media only screen and (min-width: 265px) and (max-width: 450px) {
  .long {
    max-height: 650px;
    overflow-y: scroll;
  }
}
@media only screen and (max-width: 628px) {
  .categorytwo .button {
    font-size: 13px;
    margin-right: 17px;
  }
  .seperate {
    font-weight: 400;
  }
}
@media only screen and (max-width: 398px) {
  .categorytwo button {
    font-size: 13px;
  }
  .seperate {
    font-weight: 200;
  }
}
@media only screen and (max-width: 320px) and (min-width: 300px) {
  .categorytwo button {
    font-size: 12px !important;
  }
}
@media only screen and (max-width: 449px) {
  .categorytwo .button {
    font-size: 12px;
  }
  .seperate {
    font-weight: 200;
  }
  .showcase_item__content .showcase_item_body {
    height: 350px;
  }
}
@media only screen and (max-width: 468px) and (min-width: 450px) {
  .categorytwo .button {
    font-size: 12px;
  }
  .seperate {
    font-weight: 200;
  }
}
@media only screen and (max-width: 868px) and (min-width: 800px) {
  .categorytwo button {
    margin-right: 30px;
    font-size: 15px;
  }
}
@media only screen and (max-width: 600px) and (min-width: 200px) {
  .filters {
    line-height: 0;
  }
}
@media only screen and (max-width: 299px) {
  .categorytwo .button {
    font-size: 9px;
  }
}

/* Custom job section refrenced from legal02 */
.ContentSection {
  width: 90%;
  margin: auto;
  margin-top: 25px;
}
html {
  scroll-behavior: smooth;
}
.Clientheading {
  font-family: "Merriweather", serif;
  font-weight: bolder;
  font-size: 1.6rem;
  position: relative;
  left: -43%;
  right: 650px;
  font-weight: 900;
  text-transform: uppercase;
  padding: 5% 5% 2% 5%;
  color: #fff;
}
.bg {
  background: black;
  padding: 15px;
}
.handleitems {
  margin-top: 50px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 0px;
  row-gap: 0px;
  max-width: 95%;
  margin: 0 auto;
}
.showcase_item {
  /* background-color: #fff; */
  border-color: #000;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  background-image: linear-gradient(to bottom, #f4ebd5, #c0760a);
  color: #000;
  overflow: hidden;
  position: relative;
  border-bottom: none;
}
.showcase_item--single {
  border-bottom: 1px solid #000;
}

.showcase_item_body {
  max-width: 95%;
}
.showcase_item__content {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  text-align: left;
  font-family: "Merriweather", serif;
  max-width: 100%;
  top: 0;
  /* background-color: #fff; */
  padding: 20px;
  transition: opacity 0.3s ease-out;
}
/* .apply_popover {
  position: absolute;
  bottom: 0;
  left: 35%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;
}
.apply_popover--button {
  background: transparent;
  border: none;
  color: #000 !important;
  padding-bottom: 0 !important;
} */
.display--none {
  display: none;
}
.title {
  text-transform: uppercase;
  font-size: 20px;
}
.showcase_item:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 75%;
}

.showcase_item__content .link {
  bottom: 0px;
  padding-left: 20px;
  position: absolute;
  text-align: right;
  right: 26px;
  background: transparent;
  width: 90%;
}
.p-4 {
  padding: 1.5rem !important;
}
h6 {
  font-size: 1rem;
  margin: 8px 0px;
  text-align: left;
}
p {
  line-height: 1.5rem;
}
.showcase_item__content .showcase_item_body {
  font-size: 15px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 15px;
  height: 80%;
}

#show_items::-webkit-scrollbar-track {
  background-color: transparent;
}
.link:before {
  border: solid #000;
  border-width: 0 3px 3px 0;
  content: "";
  display: block;
  margin-top: -4px;
  padding: 3px;
  position: absolute;
  right: -18px;
  top: 50%;
  transform: rotate(-45deg);
  border-color: #f7323f;
}
@media only screen and (max-width: 1019px) {
  .handleitems {
    margin-top: 50px;
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 0px;
    row-gap: 0px;
    max-width: 99%;
    margin: 0 auto;
  }
  .Clientheading {
    font-family: "Merriweather", serif;
    font-weight: bolder;
    font-size: 1.6rem;
    position: relative;
    left: -40%;
    right: 650px;
    font-weight: 900;
    text-transform: uppercase;
    padding: 5% 5% 2% 5%;
    color: #fff;
  }
  .showcase_item:nth-last-child(1) {
    /* border-bottom: 1px solid #000; */
  }
}
/*
  @media only screen and (min-width: 551px) and (max-width: 600px) {
    .showcase_item__content .showcase_item_body {
      height: 280px;
    }
    .handleitems{
      width:70%;
    }
  }

  @media only screen and (min-width: 601px) and (max-width: 650px) {
    .showcase_item__content .showcase_item_body {
      height: 280px;

    }
    .handleitems{
      width:70%;
    }
  }
  @media only screen and (min-width: 651px) and (max-width: 700px) {
    .showcase_item__content .showcase_item_body {
      height: 280px;

    }
    .handleitems{
      width:70%;
    }
  }
  @media only screen and (min-width: 701px) and (max-width: 750px) {
    .showcase_item__content .showcase_item_body {
      height: 280px;
    }
    .handleitems{
      width:70%;
    }
  }

  @media only screen and (min-width: 751px) and (max-width: 768px) {
    .showcase_item__content .showcase_item_body {
      height: 280px;
    }
    .handleitems{
      width:70%;
    }
  }

  @media only screen and (min-width: 769px) and (max-width: 850px) {
    .showcase_item__content .showcase_item_body {
      height: 175px;

    }
    .handleitems{
      width:70%;
    }
  }
  @media only screen and (min-width: 850px) and (max-width: 1019px) {
    .showcase_item__content .showcase_item_body {
      height: 175px;

    }
    .handleitems{
      width:70%;
    }
  } */
@media only screen and (min-width: 680px) and (max-width: 1019px) {
  .handleitems {
    width: 60%;
  }
}
@media only screen and (min-width: 1020px) {
  /* .showcase_item:nth-last-child(2),
  .showcase_item:nth-last-child(2) ~ .showcase_item {
    border-bottom: 1px solid black;
  } */
  .showcase_item:nth-child(even) {
    border-left: none;
  }
}
