.swiper-container {
  width: 80%;
  overflow: hidden;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: 90%;
}

.slider {
  margin-top: 50px;
}

.sliderheading {
  padding-bottom: 25px;
  text-align: center;
  font-size: 1.9vw;
  font-weight: 500;
  color: black;
  font-family: "Poppins", sans-serif;
  text-shadow: 2px 7px 5px rgba(0, 0, 0, 0.3),
    0px -4px 10px rgba(255, 255, 255, 0.3);
  left: 80px;
}

.swiper-button-next {
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  background-image: url("../../../assets/Next_Image.png") !important;
  background-size: 70%;
}

.swiper-button-prev {
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  background-image: url("../../../assets/Previous_Image.png") !important;
  background-size: 70%;
}

.text {
  background-color: #fafafa;
  float: right;
  color: #636363;
  height: 320px;
  padding-right: 30px;
  padding-left: 40px;
}

img.responsive {
  height: auto;
  width: 100%;
}

img.responsive_mob {
  float: left;
  height: 256px;
  width: 424px;
  display: none;
}

#column1,
#column2 {
  margin-right: 1%;
  width: 50%;
  display: flex;
}

#column2 p {
  margin-bottom: 0;
  overflow: hidden;
  min-height: 170px;
  font-size: 14px;
  text-align: justify;
  line-height: 1.6;
}

#column2 p a {
  cursor: pointer;
  color: rgb(238, 146, 13);
}

#columnmid {
  display: none;
  overflow: hidden;
  margin-left: 50px;
  padding-right: 30px;
  width: 480px;
  height: 120px;
  text-align: justify;
  line-height: 1.7;
  margin-right: 60px;
}

.swiper-pagination {
  bottom: 0;
  padding: 10px;
  z-index: 99 !important;
}

.slider .swiper-wrapper {
  padding-inline-start: 0px;
}

.swiper-button-next::after {
  display: none;
}

.swiper-button-prev::after {
  display: none;
}

.swiper-slide {
  background-color: #fafafa;
  font-size: 18px;
  line-height: 1.8;
  box-sizing: border-box;
  padding-top: 1%;
  padding-bottom: 5%;
  /* padding-left: 5%; */
}

.slider .swiper-slide {
  font-size: 18px;
  line-height: 1.8;
  box-sizing: border-box;
  margin: auto;
  padding-left: 0;
}

.swiper-pagination .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  background-color: #cfcfcf;
  opacity: 1;
}

.swiper-pagination .swiper-pagination-bullet-active {
  background-color: #ffb03b;
}

.box {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background-color: rgb(248, 248, 248);
  border-radius: 5px;
}

@media (min-width: 1300px) and (max-width: 2560px) {
  #column2 p {
    font-size: 1.1vw;
  }
}

@media screen and (max-width: 1300px) {
  .swiper-container {
    width: 1000px;
  }
  .sliderheading {
    text-align: center;
  }
}

@media screen and (max-width: 1000px) {
  .wrapper {
    flex-direction: column;
  }
  #column1,
  #column2 {
    width: 90%;
  }
  img.responsive {
    width: 100%;
  }
  #column2 p {
    line-height: 1.4;
  }
  .sliderheading {
    font-size: 20px;
    padding-bottom: 0px;
  }
  .swiper-container {
    width: 640px;
  }
}

@media screen and (max-width: 700px) {
  #column1,
  #column2 {
    width: 100%;
  }
  .wrapper {
    padding: 0%;
    display: block;
    margin: auto;
    height: 100%;
  }
  img.responsive {
    display: none;
  }
  img.responsive_mob {
    height: auto;
    width: 60%;
    display: block;
    padding: 0%;
    margin: auto;
  }
  #column1 {
    width: 100%;
    margin: 0%;
    padding: 0%;
    margin: auto;
    display: flex;
  }
  #column2 p {
    width: 100%;
    margin: auto;
    padding: 15px;
    padding-bottom: 50px;
  }
  .slider .swiper-pagination .swiper-pagination-bullet {
    width: 7px;
    height: 7px;
    background-color: #cfcfcf;
    opacity: 1;
  }
  .slider .swiper-pagination .swiper-pagination-bullet-active {
    background-color: #ffb03b;
  }
  .sliderheading {
    padding: 0%;
    margin: auto;
    text-align: center;
  }
  .slider .swiper-container {
    width: 95%;
    height: auto;
    margin: auto;
  }
  .slider .swiper-slide {
    font-size: 18px;
    line-height: 1.8;
    box-sizing: border-box;
    padding-top: 1%;
    padding-bottom: 5%;
    padding: 0px;
    margin: auto;
    margin-top: 10px;
  }
  .swiper-button-next {
    background-repeat: no-repeat;
    background-position: center;
    background-image: none !important;
    background-size: 60%;
  }
  .swiper-button-prev {
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    background-image: none !important;
    background-size: 60%;
  }
}
