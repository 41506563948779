/* css for main box which enclosing while client carousel cards */
.SlideContent-client {
  --color1: hsl(36, 90%, 40%);
  --color1-light: hsl(36, 90%, 35%);
  --color2: #f4ebd5;
  --color3: rgb(231, 207, 163);

  width: 95vw;
  margin: auto;
  margin: 1vh 0;
}

.mobile_container {
  --head1: clamp(0.7rem, 4vw, 1rem);
  --sub-head: clamp(0.7rem, 4vw, 0.9rem);
  --sub-txt: clamp(0.6rem, 4vw, 0.8rem);
  --desc-font: clamp(0.7rem, 4vw, 0.9rem);
  --card-width: 95vw;
  --card-height: calc(var(--card-width) * 0.66);
  --desc-height: calc(var(--card-width) * 0.2);
  --desc-width: var(--card-width);

  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  /* border: 1px solid black; */
}
/* CARD */
.mobile_container .showcase_item {
  position: relative;
  border: none;
  color: rgb(0, 0, 0);
  width: var(--card-width);
  height: var(--card-height);
  box-shadow: 0 0 5px var(--color1);
  background: linear-gradient(200deg, var(--color2) 10%, var(--color1));
}
.mobile_container .showcase_item.enlarge {
  --card-height: calc(var(--card-width) * 0.66 * 2.05);
}
.showcase_item_body {
  position: absolute;
  inset: 0;
  /* z-index: 2; */
}
/* MAIN DEATAILS */
.mobile_container .main_details {
  position: absolute;
  inset: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 0.2rem;
  padding: 1rem;
}
.mobile_container .main_details .title {
  line-height: 120%;
  text-transform: uppercase;
  font-size: var(--head1);
}
.mobile_container .main_details .category2 {
  padding: 0;
  margin: 0;
  font-size: var(--sub-txt);
  line-height: 100%;
  /* display: flex;
  align-items: baseline; */
}
.main_details .category2 .head {
  font-size: var(--sub-head);
  font-weight: bold;
  margin-right: 0.2rem;
}
.main_details .desc {
  position: relative;
  width: var(--desc-width);
  height: var(--desc-height);
  overflow: hidden;
  font-size: var(--desc-font);
  /* margin-bottom: 5%; */
  /* border: 1px solid black; */
}

/* END OF MAIN DEATAILS */

.mobile_container .showcase_item .more {
  position: absolute;
  top: 3%;
  height: 84%;
  padding: 0 1rem;
  overflow-y: scroll;
}
/* #show_items::-webkit-scrollbar-track {
  background-color: rgb(255, 255, 255);
} */
.mobile_container .showcase_item .read {
  position: absolute;
  bottom: 1%;
  right: 10%;
  cursor: pointer;
  font-size: var(--sub-head);
}
.mobile_container .read:before {
  /* border: solid rgb(0, 0, 0); */
  border-width: 0 3px 3px 0;
  content: "";
  display: block;
  margin-top: -4px;
  padding: 3px;
  position: absolute;
  right: -10px;
  top: 50%;
  transform: rotate(-45deg);
  border-color: var(--color3);
}

.showcase_item .apply_popover {
  position: absolute;
  bottom: 1%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.showcase_item .apply_popover--button {
  background: transparent;
  border: none;
  color: #000 !important;
  padding-bottom: 0 !important;
}

/* END OF CARD */
.SlideContent-client .slick-dots {
  bottom: -5vh;
}
.SlideContent-client .slick-dots li {
  transform: scale(2.5);
  margin-left: 1.5vw;
}
.SlideContent-client .slick-dots li button::before {
  color: var(--color1) !important;
}

@media (min-width: 400px) and (max-width: 450px) {
  .mobile_container {
    --card-height: calc(var(--card-width) * 0.58);
    --desc-height: calc(var(--card-width) * 0.1);
  }
  .mobile_container .showcase_item.enlarge {
    --card-height: calc(var(--card-width) * 0.58 * 2);
  }
}

@media (min-width: 451px) and (max-width: 600px) {
  .mobile_container {
    --card-height: calc(var(--card-width) * 0.48);
    --desc-height: calc(var(--card-width) * 0.1);
  }
  .mobile_container .showcase_item.enlarge {
    --card-height: calc(var(--card-width) * 0.48 * 2);
  }
}

@media (max-width: 399px) {
  .mobile_container {
    --card-height: calc(var(--card-width) * 0.69);
    --desc-height: calc(var(--card-width) * 0.13);
  }
  .mobile_container .showcase_item.enlarge {
    --card-height: calc(var(--card-width) * 0.69 * 2);
  }
}
/* @media (max-width: 329px) {
  .mobile_container .main_details {
    padding: 0.8rem;
  }
  .mobile_container {
    --head1: clamp(0.8rem, 4vw, 1.1rem);

    --card-height: fit-content;

    --desc-height: 30%;
  }
} */

/* .main-carousel {
  overflow: hidden;
  background-color: rgb(0, 0, 0);
  padding-bottom: 15px;
} */
/* .client-read-button {
  color: black;
  cursor: pointer;
  font-size: 14px;
}

.showcase_item__content .showcase_item_body_carousel {
  font-size: 15px;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 292px;
}
#showcase_item_body_carousel::-webkit-scrollbar-track {
  background: rgb(255, 255, 255);
}
.showcase_item_last {
  padding-top: 75%;
}
.client-carousel-card-title {
  font-size: 20px;
  text-transform: uppercase;
  text-align: left;
}
.client-carousel-card-article {
  text-align: left;
  font-size: 14px;
}
.showcase_odd_client:nth-child(odd) {
  margin-bottom: 5px;
  margin-left: 4px;
  margin-right: 4px;
} */

/* css for dots below carousel card */
/* .client-dots {
  text-align: center;
}

.client-custom li {
  width: 5px;
  height: 10px;
  margin: 4px;
  padding-left: 10px;
  opacity: 100%;
  align-items: center;
  background-color: rgb(231, 247, 206);
  border: 0;
  border-radius: 100px;
  box-sizing: border-box;
  color: rgb(0, 0, 0);
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  min-width: 0px;
  overflow: hidden;
  padding: 0px;
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;
  touch-action: manipulation;
  transition: background-color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s,
    box-shadow 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s,
    color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
}
.client-custom li.slick-active {
  opacity: 100%;
  background-color: rgb(173, 255, 47);
}
.slick-track {
  transform: translate3d(0, 0, 0);
}
.slick-dots li.slick-active button:before {
  opacity: 90%;
} */

/* css to overwrite bootstrap class */
/* .pt-5 {
  padding-top: 0px;
} */
/* @media only screen and (max-width: 320px) {
  .showcase_item__content .showcase_item_body_carousel {
    height: 308px;
  }
  .mobile_container {
    --head1: clamp(0.9rem, 4vw, 1.2rem);
    --sub-head: clamp(0.7rem, 4vw, 0.9rem);
    --sub-txt: clamp(0.6rem, 4vw, 0.8rem);
    --desc-font: clamp(0.7rem, 4vw, 0.9rem);
    --card-height: calc(var(--card-width) * 0.5);
  }
} */

/* @media only screen and (min-width: 321px) and (max-width: 400px) {
  .showcase_item__content .showcase_item_body_carousel {
    height: 370px;
  }
}

@media only screen and (min-width: 401px) and (max-width: 450px) {
  .showcase_item__content .showcase_item_body_carousel {
    height: 444px;
  }
}
@media only screen and (min-width: 451px) and (max-width: 500px) {
  .showcase_item__content .showcase_item_body_carousel {
    height: 505px;
  }
}

@media only screen and (min-width: 501px) and (max-width: 550px) {
  .showcase_item__content .showcase_item_body_carousel {
    height: 580px;
  }
}

@media only screen and (min-width: 551px) and (max-width: 580px) {
  .showcase_item__content .showcase_item_body_carousel {
    height: 670px;
  }
} */

/* for correcting the stopping point */
#ourclients::before {
  content: "";
  display: block;
  height: 80px;
  margin-top: -80px;
  visibility: hidden;
}
